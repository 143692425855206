import React, { Component } from 'react';
import Header from '../../components/Header'
import Footer from '../../components/Footer';
import SidebarSearch from '../../components/Sidebar/Search';
import SidebarTemples from '../../components/Sidebar/Temples';
import { stripTag, convertImage } from '../../Helpers';


export default class Blogview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            files: [],
            cover: '',
            path: '',
        };
    }

    componentDidMount() {
        let id = this.props.match.params.id;

        fetch("https://chiangmaispirit.com/api/event/" + id, {
            header: {
                "Accept": "application/json"
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result.data,
                        files: result.files,
                        path: result.path,
                        cover: result.cover,
                        id: id
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    })
                }
            )
    }

    render() {
        const { error, isLoaded, id, items, files, path, cover } = this.state;
        // console.log('file: '+files)
        if (items !== '') {
            return (
                <div className="wrapper-content">
                    <Header />
                    <div id="wrapper-content">
                        <div className="main-content">
                            <section className="page-banner blog" style={{ backgroundImage: "url(" + cover + ")" }}>
                                <div className="container">
                                    <div className="page-title-wrapper">
                                        <div className="page-title-content">
                                            <ol className="breadcrumb">
                                                <li>
                                                    <a href="./" className="link home">หน้าแรก</a>
                                                </li>
                                                <li >
                                                    <a href="../event" className="link">แนะนำเส้นทางท่องเที่ยว</a>
                                                </li>
                                                <li className="active">
                                                    <a href="#" className="link">{items.name}</a>
                                                </li>
                                            </ol>
                                            <div className="clearfix"></div>
                                            <h2 className="captions" style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{items.name}</h2>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="page-main padding-top padding-bottom">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-8 main-left">
                                            <div className="item-blog-detail">
                                                <div className="blog-post blog-text">
                                                    <div className="blog-image">
                                                        <a href="#" className="link">
                                                            <img src={cover} alt="car on a road" className="img-responsive" />
                                                        </a>
                                                    </div>
                                                    <div className="blog-content margin-bottom70">
                                                        <div className="row">
                                                            {/* <div className="col-xs-1">
                                                                <div className="date">
                                                                    <h1 className="day">{items.updated_at.substring(8, 10)}</h1>
                                                                    <div className="month">{convertMonth(items.updated_at.substring(5, 7))}</div>
                                                                    <div className="year">{items.updated_at.substring(0, 4)}</div>
                                                                </div>
                                                            </div> */}
                                                            <div className="col-xs-12 blog-text">
                                                                <h3> {items.name}</h3>

                                                                <div className="blog-descritption" dangerouslySetInnerHTML={{ __html: items.detail }}></div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 sidebar-widget">
                                            <SidebarSearch />
                                            <SidebarTemples />
                                        </div>
                                    </div>
                                </div>
                            </section>



                        </div>
                        <div id="back-top">
                            <a href="#top" className="link">
                                <i className="fa fa-angle-double-up"></i>
                            </a>
                        </div>
                    </div>

                    <Footer />
                </div>

            );
        } else {
            return (<h1 className="text-center padding-top">Loading</h1>)
        }
    }
}