import React, { Component } from 'react';

export default class WidgetAbout extends Component {
    render() {
        return (
            <section className="about-us layout-1 padding-top padding-bottom" style={{ background: "URL(https://www.fluckcarrent.com/backend/assets/img/blog/88/119/1.jpg)", backgroundSize: "cover", backgroundPositionX: "350px", backgroundRepeat: "no-repeat", backgroundColor: "#fcf7f1" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="group-title">
                                <div className="sub-title">
                                    <p className="text">Srisuphan Tourism Village</p>
                                    <i className="icons flaticon-people"></i>
                                </div>
                                <h2 className="main-title">วัดศรีสุพรรณ <br />ศูนย์รวมจิตใจชุมชนวัฒนธรรมเครื่องเงินถนนวัวลาย </h2>
                            </div>
                            <div className="about-us-wrapper">
                                <p className="">พระอุโบสถเงินหลังนี้เป็นพระอุโบสถหลังใหม่ที่ปฏิสังขรณ์จากฐานและเขตพัทธสีมาของอุโบสถหลังเดิมที่ชำรุดทรุดโทรมลง
                                โดยใช้กรรมวิธีของภูมิปัญญาท้องถิ่นของชุมชนวัวลาย แท้จริงแล้วอุโบสถนี้ไม่ใช่ทำจากเงินแท้ทั้งหลัง แต่ใช้วัสดุจากอลูมิเนียม (วัสดุแทนเงิน)
                                เงินผสม และเงินบริสุทธิ์ สลักลวดลาย บุดุน ประดับตกแต่งตั้งแต่หลังคา ผนังภายในภายนอกรวมทั้งหลัง
                                ศรีสุพรรณอารามหรือวัดศรีสุพรรณหรือวัดสุภัน ตั้งอยู่ทางทิศใต้ของเมืองเชียงใหม่ อยู่บนพื้นที่ระหว่างกำแพงชั้นในและกำแพงชั้นนอก
                                ในอดีตเป็นวัดหนึ่งในหมวดอุโบสถวัดนันทารามขึ้นแก่วัดหมื่นสาน</p>
                                <div className="group-list">
                                    <ul className="list-unstyled about-us-list">
                                        <li>
                                            <p className="text">อุโบสถเงิน แห่งแรกของโลก</p>
                                        </li>
                                        <li>
                                            <p className="text">ชุมชนเครื่องเงินถนนวัวลาย</p>
                                        </li>
                                        <li>
                                            <p className="text">โครงการธนาคารไข่</p>
                                        </li>
                                        <li>
                                            <p className="text">กิจกรรมของวัด</p>
                                        </li>
                                        <li>
                                            <p className="text">บูชาพระพุทธเจ้า 7 ตื้อ</p>
                                        </li>
                                    </ul>

                                </div>
                                <div className="group-button">
                                    <a href="./srisupan" className="btn btn-maincolor">เยื่ยมชมวัดศรีสุพรรณ</a>
                                    {/* <a href="about-us.html" className="btn">กิจกรรมของวัด</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div data-wow-delay="0.5s" className="about-us-image wow zoomIn">
                                {/* <img src="https://aikidocmu.files.wordpress.com/2014/01/map-rsk-thai.png?w=584" alt="" className="img-responsive" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}