import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from '../Home';
import Temple from '../Temple';
import Templeview from '../Temple/templeView';
import Trails from '../Trails';
import Trailsdetail from '../Trailsdetail';
import Projects from '../Project';
import Blog from '../Blog/blogList';
import Blogview from '../Blog/blogView';
import Event from '../Event/eventList';
import Eventview from '../Event/eventView';
import Bank from '../Bank';
import Srisupan from '../Srisupan';
import Store from '../Store';

const Root = () => {
    return (
        <div className="body-wrapper">
            <div className="wrapper-mobile-nav">
                <div className="header-topbar">
                    <div className="topbar-search search-mobile">
                        <form className="search-form">
                            <div className="input-icon">
                                <i className="btn-search fa fa-search"></i>
                                <input type="text" placeholder="Search here..." className="form-control" />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="header-main">
                    <div className="menu-mobile">
                        <ul className="nav-links nav navbar-nav">
                            <li className="dropdown">
                                <a href="index.html" className="main-menu">
                                    <span className="text">Home</span>
                                </a>
                                <span className="icons-dropdown">
                                    <i className="fa fa-angle-down"></i>
                                </span>
                                <ul className="dropdown-menu dropdown-menu-1">
                                    <li>
                                        <a href="index.html" className="link-page">Homepage default</a>
                                    </li>
                                    <li>
                                        <a href="homepage-02.html" className="link-page">Homepage 02</a>
                                    </li>
                                    <li>
                                        <a href="homepage-03.html" className="link-page">Homepage 03</a>
                                    </li>
                                    <li>
                                        <a href="homepage-04.html" className="link-page">Homepage 04</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="about-us.html" className="main-menu">
                                    <span className="text">about</span>
                                </a>
                            </li>
                            <li className="dropdown">
                                <a href="tour-result.html" className="main-menu">
                                    <span className="text">Tour</span>
                                </a>
                                <span className="icons-dropdown">
                                    <i className="fa fa-angle-down"></i>
                                </span>
                                <ul className="dropdown-menu dropdown-menu-1">
                                    <li>
                                        <a href="tour-result.html" className="link-page">tour result</a>
                                    </li>
                                    <li>
                                        <a href="tour-view.html" className="link-page">tour view</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="dropdown">
                                <a href="hotel-result.html" className="main-menu">
                                    <span className="text">packages</span>
                                </a>
                                <span className="icons-dropdown">
                                    <i className="fa fa-angle-down"></i>
                                </span>
                                <ul className="dropdown-menu dropdown-menu-1">
                                    <li>
                                        <a href="hotel-result.html" className="link-page">hotel result</a>
                                    </li>
                                    <li>
                                        <a href="hotel-view.html" className="link-page">hotel view</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="dropdown active">
                                <a href="blog.html" className="main-menu">
                                    <span className="text">blog</span>
                                </a>
                                <span className="icons-dropdown">
                                    <i className="fa fa-angle-down"></i>
                                </span>
                                <ul className="dropdown-menu dropdown-menu-1">
                                    <li>
                                        <a href="blog.html" className="link-page">blog list</a>
                                    </li>
                                    <li>
                                        <a href="blog-detail.html" className="link-page">blog detail</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="dropdown">
                                <a href="car-rent-result.html" className="main-menu">
                                    <span className="text">page</span>
                                </a>
                                <span className="icons-dropdown">
                                    <i className="fa fa-angle-down"></i>
                                </span>
                                <ul className="dropdown-menu dropdown-menu-1">
                                    <li>
                                        <a href="car-rent-result.html" className="link-page">car rent result</a>
                                    </li>
                                    <li>
                                        <a href="cruises-result.html" className="link-page">cruises result</a>
                                    </li>
                                    <li>
                                        <a href="404.html" className="link-page">page 404</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="contact.html" className="main-menu">
                                    <span className="text">contact</span>
                                </a>
                            </li>
                        </ul>
                        <ul className="list-unstyled list-inline login-widget">
                            <li>
                                <a href="#" className="item">login</a>
                            </li>
                            <li>
                                <a href="#" className="item">register</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <Switch>
                <Route
                    component={Home}
                    exact
                    path="/" />
                <Route
                    component={Temple}
                    exact
                    path="/temples" />
                <Route
                    component={Templeview}
                    exact
                    path="/temples/:id" />
                <Route
                    component={Store}
                    exact
                    path="/store/:id" />
                <Route
                    component={Trails}
                    exact
                    path="/trails/:view" />
                <Route
                    component={Trailsdetail}
                    exact
                    path="/trail/:id" />
                <Route
                    component={Projects}
                    exact
                    path="/projects" />
                <Route
                    component={Blog}
                    exact
                    path="/blog" />
                <Route
                    component={Blogview}
                    exact
                    path="/blog/:id" />
                <Route
                    component={Event}
                    exact
                    path="/event" />
                <Route
                    component={Eventview}
                    exact
                    path="/event/:id" />
                <Route
                    component={Bank}
                    exact
                    path="/bank" />
                <Route
                    component={Srisupan}
                    exact
                    path="/srisupan" />
            </Switch>
        </div>
    )
}

export default Root;