import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import Root from './container/Routes/Root';

// import './assets/font/font-icon/font-awesome/css/font-awesome.css'


// import './assets/font/font-icon/font-flaticon/flaticon.css';
// import './assets/libs/bootstrap/css/bootstrap.min.css';
// import './assets/libs/animate/animate.css';
// import './assets/libs/slick-slider/slick.css';
// import './assets/libs/slick-slider/slick-theme.css';
// import './assets/libs/selectbox/css/jquery.selectbox.css';
// import './assets/libs/please-wait/please-wait.css';
// // import './assets/libs/fancybox/css/jquery.fancybox.css?v=2.1.5';
// import './assets/libs/fancybox/css/jquery.fancybox-buttons.css?v=1.0.5';
// import './assets/libs/fancybox/css/jquery.fancybox-thumbs.css?v=1.0.7';

// import './assets/css/layout.css';
// import './assets/css/components.css';
// import './assets/css/responsive.css';
// import './assets/css/color.css';
// import './assets/css/color-1/color.css';

// import './assets/libs/bootstrap-datepicker/css/bootstrap-datepicker.min.css';



render(
    <BrowserRouter>
        <Root/>
    </BrowserRouter>,
    document.getElementById('root')
);