import React, { Component } from 'react';
import { convertImage, convertMonth, stripTag } from '../../../Helpers';

export default class WidgetBooking extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            blog: [],
            event: [],
            eventPic: [],
            food: [],
            foodPic: [],
            wualai: '',
            path: '',
        };
    }

    componentDidMount() {

        fetch("https://chiangmaispirit.com/api/widget", {
            header: {
                "Accept": "application/json"
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        blog: result.blog,
                        event: result.event,
                        eventPic: result.event_pic,
                        food: result.food,
                        foodPic: result.food_pic,
                        wualai: result.wualai,
                        path: result.path
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    })
                }
            )
    }

    render() {
        const { blog, event, eventPic, food, foodPic, path, wualai } = this.state;
        return (
            <section>

                <div className="tab-search tab-search-long tab-search-default">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <ul role="tablist" className="nav nav-tabs ">
                                    <li role="presentation" className="tab-btn-wrapper active">
                                        <a href="#flight" aria-controls="flight" role="tab" data-toggle="tab" className="tab-btn">
                                            <span className="text">Hot Journey</span>
                                            <span className="xs">FIND YOUR FLIGHT</span>
                                        </a>
                                    </li>
                                    <li role="presentation" className="tab-btn-wrapper">
                                        <a href="#transfer" aria-controls="transfer" role="tab" data-toggle="tab" className="tab-btn">
                                            <span className="text">ปฏิทินท่องเที่ยว</span>
                                            <span className="xs">FIND TRANSFER</span>
                                        </a>
                                    </li>
                                    <li role="presentation" className="tab-btn-wrapper">
                                        <a href="#hotel" aria-controls="hotel" role="tab" data-toggle="tab" className="tab-btn">
                                            <span className="text">ร้านอาหารริมทาง</span>
                                            <span className="xs">FIND HOTEL</span>
                                        </a>
                                    </li>
                                    <li role="presentation" className="tab-btn-wrapper">
                                        <a href="#wualai" aria-controls="wualai" role="tab" data-toggle="tab" className="tab-btn">
                                            <span className="text">ท่องเที่ยวชุมชนวัวลาย</span>
                                            <span className="xs">FIND TOURS</span>
                                        </a>
                                    </li>
                                    <li role="presentation" className="tab-btn-wrapper">
                                        <a href="#tours" aria-controls="tours" role="tab" data-toggle="tab" className="tab-btn">
                                            <span className="text">ถนนคนเดิน</span>
                                            <span className="xs">FIND TOURS</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content-bg">
                        <div className="container">

                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="tab-content">
                                        <div role="tabpanel" id="flight" className="tab-pane fade in active">
                                            <div className="find-widget find-flight-widget widget" style={{ paddingTop: "30px" }}>
                                                <h2 className="text-center">เสาร์ติ๊ดแอ่วเจียงใหม่ ม๋วนใจ๋ขนาด</h2>
                                                {(blog ?
                                                    (<div className="new-layout" style={{ marginTop: '30px' }}>
                                                        <div className="image-wrapper">
                                                            <a href={'./blog/' + blog.id} className="link">

                                                                <img src={path + '/Uploads/Blogs/' + blog.image} alt="" className="img-responsive" style={{ height: '400px' }} />
                                                            </a>
                                                            <div className="description">{blog.description} ...</div>
                                                        </div>
                                                        <div className="content-wrapper">
                                                            <a href={'./blog/' + blog.id} className="title margin-top70">{blog.name}</a>
                                                            {/* <ul className="info list-inline list-unstyled">
                                                                <li><a href="./temples" className="link">2017-11-13 </a></li>
                                                                <li><a href="./temples" className="link">admin</a></li>
                                                            </ul> */}
                                                            <p className="">{blog.description}</p>
                                                            <a href={'./blog/' + blog.id} className="btn" style={{ marginRight: '15px' }}>อ่านต่อ...</a>
                                                            <a href={'./blog'} className="btn btn-maincolor">ดูทั้งหมด</a>
                                                        </div>
                                                    </div>)
                                                    : (<h1>Loading</h1>)
                                                )}
                                            </div>
                                        </div>
                                        <div role="tabpanel" id="transfer" className="tab-pane fade">
                                            <div className="find-widget find-transfer-widget widget" style={{ paddingTop: "30px" }}>
                                                <h2 className="text-center">(ม่วน งัน สั่นเล้า) เที่ยวได้ทั้งปีไม่มีพลาด</h2>
                                                {(event ?
                                                    (<div className="row" style={{ marginTop: '30px' }}>
                                                        {event.map((item, index) => (
                                                            <div className="col-md-6" key={item.name}>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <a href={"./event/" + item.id} className="link">
                                                                            <img src={convertImage(path, eventPic[index])} alt="a car on a road" className="img-responsive" />
                                                                        </a>
                                                                    </div>
                                                                    <div className="col-md-6 text-left">
                                                                        {/* <div className="col-xs-2">
                                                                        <div className="row">
                                                                            <div className="date">
                                                                                <h1 className="day">{item.start_datetime.substring(8, 10)}</h1>
                                                                                <div className="month">{convertMonth(item.start_datetime.substring(5, 7))}</div>
                                                                                <div className="year">{item.start_datetime.substring(0, 4)}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                        {/* <div className="col-xs-12"> */}
                                                                        {/* <a href={"./event/" + item.id} className="heading"> */}
                                                                        <h4>{item.name}</h4>
                                                                        {/* </a> */}

                                                                        <p className="preview">{stripTag(item.detail).substring(0, 150)}</p>
                                                                        <a href={"./event/" + item.id}
                                                                            className="btn btn-gray btn-fit btn-capitalize">อ่านต่อ...</a>
                                                                        {/* </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>)
                                                    : (<h1>Loading</h1>)
                                                )}
                                            </div>
                                        </div>
                                        <div role="tabpanel" id="hotel" className="tab-pane fade">
                                            <div className="find-widget find-hotel-widget widget" style={{ paddingTop: "30px" }}>
                                                <h2 className="text-center">(ของกิ๋นลำๆ) สุดยอดร้านอาหารริมทาง </h2>
                                                {(food ?
                                                    (<div className="row" style={{ marginTop: '30px' }}>
                                                        {food.map((item, index) => (
                                                            <div className="col-md-4" key={index}>
                                                                <div className="tours-layout">
                                                                    <div className="image-wrapper">
                                                                        <a href={"./store/" + item.id} className="link">
                                                                            <img src={foodPic[index] != '' ? convertImage(path, foodPic[index]) : 'assets/images/homepage/new-2.jpg'} alt="" className="img-responsive" style={{ height: '240px' }} />
                                                                        </a>
                                                                        <div className="title-wrapper">
                                                                            <a href={"./store/" + item.id} className="title">{item.name}</a>
                                                                            <i className="icons fa fa-star"></i>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>

                                                        ))}
                                                    </div>)
                                                    : (<h1>Loading</h1>)
                                                )}
                                            </div>
                                        </div>
                                        <div role="tabpanel" id="wualai" className="tab-pane fade">
                                            <div className="find-widget find-tours-widget widget container">
                                                <div className=" text-left">
                                                    <div className="col-sm-5">
                                                        <img src="https://i.pinimg.com/originals/a4/76/ea/a476ea5d07cf4a91f16cd8808f11a0e9.jpg" className="img-responsive" />
                                                    </div>
                                                    <div className="col-sm-7">
                                                        <h2>{wualai.name}</h2>
                                                        <p>{wualai.overview}</p>
                                                        <p style={{ marginBottom: "30px" }}><a href={'./store/' + wualai.id} className="btn" style={{ marginRight: '15px' }}>อ่านต่อ...</a></p>
                                                        <h4> ► เยี่ยมชมอุโบสถเงินหลังแรกของโลกและร่วมเรียนรู้สืบสานสร้างสรรค์ภูมิปัญญาท้องถิ่น</h4>
                                                        <h4> ► ร่วมทำบุญออนไลน์ผ่านโครงการ “ธนาคารไข่สิริยศวดี”</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ paddingBottom: '30px' }} >
                                <div className="col-md-2 col-xs-6">

                                    <div className="media">
                                        <div className="media-left media-middle">
                                            <a href={'../trails/main'}>
                                                <img className="media-object" src="https://image.flaticon.com/icons/svg/3301/3301557.svg" alt="..." style={{ width: '40px' }} />
                                            </a>
                                        </div>
                                        <div className="media-body">
                                            <span className="media-heading">เส้นทางสายหลัก<br />Main route</span>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-2 col-xs-6">
                                    <div className="media">
                                        <div className="media-left media-middle">
                                            <a href={'../trails/photo'}>
                                                <img className="media-object" src="https://image.flaticon.com/icons/svg/2268/2268601.svg" alt="..." style={{ width: '40px' }} />
                                            </a>
                                        </div>
                                        <div className="media-body">
                                            <span className="media-heading">เส้นทางถ่ายภาพ <br /> Take Photo</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 col-xs-6">
                                    <div className="media">
                                        <div className="media-left media-middle">
                                            <a href={'../trails/nature'}>
                                                <img className="media-object" src="https://image.flaticon.com/icons/svg/3175/3175223.svg" alt="..." style={{ width: '40px' }} />
                                            </a>
                                        </div>
                                        <div className="media-body">
                                            <span className="media-heading">เส้นทางธรรมชาติ <br />nature & forrest</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 col-xs-6">
                                    <div className="media">
                                        <div className="media-left media-middle">
                                            <a href={'../trails/merit'}>
                                                <img className="media-object" src="https://image.flaticon.com/icons/svg/3430/3430012.svg" alt="..." style={{ width: '40px' }} />
                                            </a>
                                        </div>
                                        <div className="media-body">
                                            <span className="media-heading">เส้นทางสายบุญ<br />Make merit</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 col-xs-6">
                                    <div className="media">
                                        <div className="media-left media-middle">
                                            <a href={'../trails/shopping'}>
                                                <img className="media-object" src="https://image.flaticon.com/icons/svg/825/825561.svg" alt="..." style={{ width: '40px' }} />
                                            </a>
                                        </div>
                                        <div className="media-body">
                                            <span className="media-heading">เส้นทางช็อปปิ้ง<br />Shopping</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 col-xs-6">
                                    <div className="media">
                                        <div className="media-left media-middle">
                                            <a href={'../trails/all'}>
                                                <img className="media-object" src="https://image.flaticon.com/icons/svg/555/555993.svg" alt="..." style={{ width: '40px' }} />
                                            </a>
                                        </div>
                                        <div className="media-body">
                                            <span className="media-heading">เส้นทางทั้งหมด<br />View more...</span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section >

        );

    }
}