import React, { Component } from 'react';
import Header from '../../components/Header'
import TrailList from './trailsList.js';
import Footer from '../../components/Footer';

export default class Temple extends Component {
    render() {
        let bg = '../../images/RoadMap.jpg';
        let view = this.props.match.params.view;

        return (
            <div className="wrapper-content">
                <Header />
                <div id="wrapper-content">
                    <div className="main-content">
                        <section className="page-banner blog" style={{ backgroundImage: "url(" + bg + ")" }}>
                            <div className="container">
                                <div className="page-title-wrapper">
                                    <div className="page-title-content">
                                        <ol className="breadcrumb">
                                            <li>
                                                <a href="/" className="link home">หน้าแรก</a>
                                            </li>
                                            <li className="active">
                                                <a href="#" className="link">เส้นทางท่องเที่ยว</a>
                                            </li>
                                        </ol>
                                        <div className="clearfix"></div>
                                        <h2 className="captions" style={{ maxWidth: "100%" }}>เส้นทางท่องเที่ยวเชียงใหม่</h2>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="container">
                            <div className="row" style={{ paddingTop: '30px', paddingBottom: '30px' }} >
                                <div className="col-md-2 col-xs-6">

                                    <div className="media">
                                        <div className="media-left media-middle">
                                            <a href={'../trails/main'}>
                                                <img className="media-object" src="https://image.flaticon.com/icons/svg/3301/3301557.svg" alt="..." style={{ width: '40px' }} />
                                            </a>
                                        </div>
                                        <div className="media-body">
                                            <span className="media-heading">เส้นทางสายหลัก<br />Main route</span>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-2 col-xs-6">
                                    <div className="media">
                                        <div className="media-left media-middle">
                                            <a href={'../trails/photo'}>
                                                <img className="media-object" src="https://image.flaticon.com/icons/svg/2268/2268601.svg" alt="..." style={{ width: '40px' }} />
                                            </a>
                                        </div>
                                        <div className="media-body">
                                            <span className="media-heading">เส้นทางถ่ายภาพ <br /> Take Photo</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 col-xs-6">
                                    <div className="media">
                                        <div className="media-left media-middle">
                                            <a href={'../trails/nature'}>
                                                <img className="media-object" src="https://image.flaticon.com/icons/svg/3175/3175223.svg" alt="..." style={{ width: '40px' }} />
                                            </a>
                                        </div>
                                        <div className="media-body">
                                            <span className="media-heading">เส้นทางธรรมชาติ <br />nature & forrest</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 col-xs-6">
                                    <div className="media">
                                        <div className="media-left media-middle">
                                            <a href={'../trails/merit'}>
                                                <img className="media-object" src="https://image.flaticon.com/icons/svg/3430/3430012.svg" alt="..." style={{ width: '40px' }} />
                                            </a>
                                        </div>
                                        <div className="media-body">
                                            <span className="media-heading">เส้นทางสายบุญ<br />Make merit</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 col-xs-6">
                                    <div className="media">
                                        <div className="media-left media-middle">
                                            <a href={'../trails/shopping'}>
                                                <img className="media-object" src="https://image.flaticon.com/icons/svg/825/825561.svg" alt="..." style={{ width: '40px' }} />
                                            </a>
                                        </div>
                                        <div className="media-body">
                                            <span className="media-heading">เส้นทางช็อปปิ้ง<br />Shopping</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 col-xs-6">
                                    <div className="media">
                                        <div className="media-left media-middle">
                                            <a href={'../trails/all'}>
                                                <img className="media-object" src="https://image.flaticon.com/icons/svg/555/555993.svg" alt="..." style={{ width: '40px' }} />
                                            </a>
                                        </div>
                                        <div className="media-body">
                                            <span className="media-heading">เส้นทางทั้งหมด<br />View more...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <TrailList view={view} />
                    </div>
                    <div id="back-top">
                        <a href="#top" className="link">
                            <i className="fa fa-angle-double-up"></i>
                        </a>
                    </div>
                </div>

                <Footer />

            </div>

        );
    }
}