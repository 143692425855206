import React, { Component } from 'react';
import Header from '../../components/Header'
import Footer from '../../components/Footer';
import SidebarSearch from '../../components/Sidebar/Search';
import SidebarPlace from '../../components/Sidebar/Place';
import { stripTag, convertImage } from '../../Helpers';
import Columns from 'react-columns';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow,
} from "react-google-maps";

export default class Store extends Component {


    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            files: [],
            content: '',
            cover: '',
            path: '',
        };
    }

    componentDidMount() {
        let id = this.props.match.params.id;

        fetch("https://chiangmaispirit.com/api/temples/" + id, {
            header: {
                "Accept": "application/json"
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result.data,
                        files: result.file,
                        path: result.path,
                        cover: convertImage(result.path, result.file),
                        content: result.data.description.replace(/[/]photos[/]shares/g, 'https://chiangmaispirit.com/photos/shares'),

                        id: id
                    });
                    // cover = files;
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    })
                }
            )
    }

    render() {
        const { error, isLoaded, id, items, files, path, cover, content } = this.state;

        const MapWithAMarker = withScriptjs(withGoogleMap(props =>
            <GoogleMap
                defaultZoom={14}
                defaultCenter={{ lat: parseFloat(items.latitude), lng: parseFloat(items.latitude) }}
            >
                {console.log('data : ' + JSON.stringify(props.data))}
                {

                    <Marker
                        position={{ lat: parseFloat(items.latitude), lng: parseFloat(items.longitude) }}
                    >
                        <InfoWindow onCloseClick={props.onToggleOpen}>
                            <div>
                                {items.name}
                            </div>
                        </InfoWindow>
                    </Marker>

                }

            </GoogleMap >
        ));

        // console.log('file: '+files)
        if (items !== '') {
            return (
                <div className="wrapper-content">
                    <Header />
                    <div id="wrapper-content">
                        <div className="main-content">
                            <section className="page-banner blog" style={{ backgroundImage: "url(" + cover + ")" }}>
                                <div className="container">
                                    <div className="page-title-wrapper">
                                        <div className="page-title-content">
                                            <ol className="breadcrumb">
                                                <li>
                                                    <a href="./" className="link home">หน้าแรก</a>
                                                </li>
                                                <li className="active">
                                                    <a href="#" className="link">ที่เที่ยวแนะนำ</a>
                                                </li>
                                            </ol>
                                            <div className="clearfix"></div>
                                            <h2 className="captions" style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{items.name}</h2>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="page-main padding-top padding-bottom">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-8 main-left">
                                            <div className="item-blog-detail">
                                                <div className="blog-post blog-text">
                                                    <div className="blog-image">
                                                        {(items.latitude != null ?
                                                            (
                                                                <MapWithAMarker googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAL_eMAgCtTjJEBWQDpwJGBF1OuXbYUR9s&v=3.exp&libraries=geometry,drawing,places"
                                                                    loadingElement={<div style={{ height: `100%` }} />}
                                                                    containerElement={<div style={{ height: `400px` }} />}
                                                                    mapElement={<div style={{ height: `100%` }} />}
                                                                />
                                                            )
                                                            :
                                                            (
                                                                <a href="#" className="link">
                                                                    {(files !== '' ?
                                                                        (<img src={cover} alt="car on a road" className="img-responsive" />)
                                                                        : (<h1>Loading</h1>)
                                                                    )}
                                                                </a>
                                                            )
                                                        )}


                                                    </div>
                                                    <div className="blog-content margin-bottom70">
                                                        <div className="row">
                                                            {/* <div className="col-xs-1">
                                                                <div className="date">
                                                                    <h1 className="day">{items.updated_at.substring(8, 10)}</h1>
                                                                    <div className="month">{convertMonth(items.updated_at.substring(5, 7))}</div>
                                                                    <div className="year">{items.updated_at.substring(0, 4)}</div>
                                                                </div>
                                                            </div> */}
                                                            <div className="col-xs-12 blog-text">
                                                                <h3> {items.name}</h3>
                                                                {/* <blockquote>{items.description}</blockquote> */}

                                                                <div className="blog-descritption" dangerouslySetInnerHTML={{ __html: content }} style={{ marginBottom: '30px' }}></div>

                                                                <Columns columns="2">
                                                                    {files.map((item, index) => (
                                                                        <img src={path + item.replace('/home/cmspirit/domains/chiangmaispirit.com/public_html/public', '')} alt="" style={{ padding: '5px' }} />
                                                                        // <h2>{convertImage(path, item)}</h2>
                                                                        // <h2>{item.replace('/home/cmspirit/domains/chiangmaispirit.com/public_html/public', '')}</h2>
                                                                    ))}
                                                                </Columns>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 sidebar-widget">
                                            <SidebarSearch />
                                            <SidebarPlace />
                                        </div>
                                    </div>
                                </div>
                            </section>



                        </div>
                        <div id="back-top">
                            <a href="#top" className="link">
                                <i className="fa fa-angle-double-up"></i>
                            </a>
                        </div>
                    </div>

                    <Footer />
                </div>

            );
        } else {
            return (<h1 className="text-center padding-top">Loading</h1>)
        }
    }
}