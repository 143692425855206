import React, { Component } from 'react';

export default class WidgetMerit extends Component {
    render() {
        return (
            <section className="travelers" style={{ backgroundColor: "#000" }, { backgroundImage: "none" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="traveler-wrapper padding-top padding-bottom">
                                <div className="group-title white">
                                    <div className="sub-title">
                                        <p className="text">Digital Merit Making 4.0</p>
                                        <i className="icons flaticon-people"></i>
                                    </div>
                                    <h2 className="main-title">ร่วมทำบุญออนไลน์ <br /> บริจาคไข่ไก่สิริยศวดี <br />บูชาพระพุทธเจ้า 7 ตื้อ</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="traveler-list">
                                <div className="traveler">
                                    <div className="cover-image">
                                        <img src="https://www.newtv.co.th/images/content/ct_20190714052130227.jpg" alt="" style={{ width: "100%" }} />
                                    </div>
                                    <div className="wrapper-content">
                                        <div className="avatar">
                                            <img src="https://image.flaticon.com/icons/svg/2943/2943072.svg" alt="" className="img-responsive" />
                                        </div>
                                        <p className="name">ทำบุญ</p>
                                        <p className="address">ตักบาตรเช้า</p>
                                        <p className="description">"การตักบาตร คือประเพณีอย่างหนึ่งที่ชาวพุทธปฏิบัติกันมาแต่สมัยพุทธกาล พระภิกษุจะถือบาตรออกบิณฑบาตเพื่อรับอาหารหรือทานอื่นๆ ตามหมู่บ้านในเวลาเช้า..."</p>
                                    </div>
                                </div>
                                <div className="traveler">
                                    <div className="cover-image">
                                        <img src="https://cms.kapook.com/uploads/tag/24/ID_23785_5e129ce2b9117.jpg" alt="" style={{ width: "100%" }} />
                                    </div>
                                    <div className="wrapper-content">
                                        <div className="avatar">
                                            <img src="https://image.flaticon.com/icons/svg/2943/2943023.svg" alt="" className="img-responsive" />
                                        </div>
                                        <p className="name">ทำบุญ</p>
                                        <p className="address">ซื้อไข่ไก่ยศวดีถวายพระเจ้าเจ็ดตื้อ</p>
                                        <p className="description">"การถวายสังฆทาน หมายถึงการ ถวายสิ่งของจตุปัจจัยวัตถุแก่หมู่พระสงฆ์ โดยไม่เลือกระบุถวายเฉพาะเจาะจงแก่พระสงฆ์รูปใดรูปหนึ่ง กล่าวคือ ถวายเข้าเป็นสิทธิ..."</p>
                                    </div>
                                </div>
                                <div className="traveler">
                                    <div className="cover-image">
                                        <img src="https://ak.picdn.net/shutterstock/videos/16441249/thumb/1.jpg" alt="" style={{ width: "100%" }} />
                                    </div>
                                    <div className="wrapper-content">
                                        <div className="avatar">
                                            <img src="https://image.flaticon.com/icons/svg/3142/3142512.svg" alt="" className="img-responsive" />
                                        </div>
                                        <p className="name">ทำบุญ</p>
                                        <p className="address">บริจาคไข่ไก่และข้าวกล่องโรงทาน</p>
                                        <p className="description">"การถวายสังฆทาน หมายถึงการ ถวายสิ่งของจตุปัจจัยวัตถุแก่หมู่พระสงฆ์ โดยไม่เลือกระบุถวายเฉพาะเจาะจงแก่พระสงฆ์รูปใดรูปหนึ่ง กล่าวคือ ถวายเข้าเป็นสิทธิ..."</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}