import React, { Component } from 'react';

export default class SidebarSearch extends Component {

    render() {

        return (
        <div className="search-widget widget">
            <form>
                <div className="input-group search-wrapper">
                    <input type="text" placeholder="Search..." className="search-input form-control" />
                    <span className="input-group-btn">
                        <button type="submit" className="btn submit-btn">
                            <span className="fa fa-search"></span>
                        </button>
                    </span>
                </div>
            </form>
        </div>
        )

    }
}