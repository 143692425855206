import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { appendScript } from '../../Helpers';
import { convertImage, convertMonth, stripTag } from '../../Helpers'
import SidebarSearch from '../../components/Sidebar/Search';
import SidebarTemples from '../../components/Sidebar/Temples';


export default class TrailList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            files: [],
            path: '',
        };
    }

    async componentDidMount() {

        // alert(this.props.view)
        appendScript(process.env.PUBLIC_URL + '/assets/js/pages/blog.js');


        await fetch("https://chiangmaispirit.com/api/trials/" + this.props.view)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result.data,
                        files: result.data.files,
                        path: result.path
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    })
                }
            )
    }

    render() {
        const { error, isLoaded, items, files, path } = this.state;
        console.log(files)
        if (error) {
            return <div> Error: {error.message} </div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (

                <section className="page-main padding-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 main-left blog-wrapper">
                                {items.map((item, index) => (
                                    <div className="blog-post" key={item.trail.name}>
                                        <div className="blog-image">
                                            <a href={"../trail/" + item.trail.id} className="link">
                                                <img src={convertImage(path, item.files[Math.floor(Math.random() * item.files.length)])} alt="a car on a road" className="img-responsive" />
                                            </a>
                                        </div>
                                        <div className="blog-content">
                                            <div className="col-xs-2">
                                                <div className="row">
                                                    <div className="text date">
                                                        <h1 className="day">{item.store.length}</h1>
                                                        <div className="month">สถานที่</div>
                                                        <div className="year">แนะนำ</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-10 content-wrapper">
                                                <a href={"../trail/" + item.trail.id} className="heading text">{item.trail.plan.name}</a>
                                                {/* <h5 className="meta-info">Posted By :
                                                    <span>John Smith</span>
                                                    <span className="sep">/</span>
                                                    <span className="view-count fa-custom">56</span>
                                                    <span className="comment-count fa-custom">239</span>
                                                </h5> */}
                                                <p className="preview">{stripTag(item.trail.plan.description).substring(0, 400)}</p>
                                                <a href={"../trail/" + item.trail.id}
                                                    className="btn btn-gray btn-fit btn-capitalize">อ่านต่อ...</a>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <nav className="pagination-list margin-top70">
                                    <ul className="pagination">
                                        <li>
                                            <a href="#" aria-label="Previous" className="btn-pagination previous">
                                                <span aria-hidden="true" className="fa fa-angle-left"></span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="btn-pagination active">01</a>
                                        </li>
                                        <li>
                                            <a href="#" className="btn-pagination">02</a>
                                        </li>
                                        <li>
                                            <a href="#" className="btn-pagination">03</a>
                                        </li>
                                        <li>
                                            <a href="#" className="btn-pagination">...</a>
                                        </li>
                                        <li>
                                            <a href="#" aria-label="Next" className="btn-pagination next">
                                                <span aria-hidden="true" className="fa fa-angle-right"></span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="col-md-4 sidebar-widget">
                                <SidebarSearch />
                                <SidebarTemples />
                            </div>
                        </div>
                    </div>
                </section>


            );
        }
    }
}