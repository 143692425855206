import React, { Component } from 'react';
import Header from '../../components/Header'
import Background from '../../images/temple.jpg'
import Trailsview from '../../components/Trailsview';
import { convertImage } from '../../Helpers';
import Footer from '../../components/Footer';

export default class Traildetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            files: [],
            path: '',
        };
    }

    componentDidMount() {
        let id = this.props.match.params.id;

        fetch("https://chiangmaispirit.com/api/trails/" + id, {
            header: {
                "Accept": "application/json"
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        // items: result.data.plan,
                        files: result.file,
                        path: result.path,
                        store: result.store,
                        cover: result.cover,
                        id: id,
                        items: result.data.plan

                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    })
                }
            )
    }

    render() {
        const { error, isLoaded, items, files, path, id, store, cover } = this.state;
        // if (error) {
        //     return <div> Error: {error.message} </div>;
        //     console.log('Error:' + error.message);
        // } else if (!isLoaded) {
        //     return <div style={{ visibility: "hidden", position: "absolute" }}>Loading...</div>;
        //     console.log('Loading');
        // } else {
        return (
            <div className="wrapper-content">
                <Header />
                <div id="wrapper-content">
                    <div className="main-content">
                        <section className="page-banner blog" style={{ backgroundImage: "url(" + cover + ")" }}>
                            <div className="container">
                                <div className="page-title-wrapper">
                                    <div className="page-title-content">
                                        <ol className="breadcrumb">
                                            <li>
                                                <a href="/" className="link home">หน้าแรก</a>
                                            </li>
                                            <li>
                                                <a href="/trails" className="link">เส้นทางท่องเที่ยว</a>
                                            </li>
                                        </ol>
                                        <div className="clearfix"></div>
                                        <h2 className="captions" style={{ maxWidth: "100%" }}>{items.name}</h2>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* {store.length} */}
                        <Trailsview id={id} items={items} files={files} path={path} storeObj={store} />
                    </div>
                    <div id="back-top">
                        <a href="#top" className="link">
                            <i className="fa fa-angle-double-up"></i>
                        </a>
                    </div>
                </div>

                <Footer />
            </div>

        );
        // }
    }
}