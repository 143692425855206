import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Footer extends Component {

    render() {
        return (

            <footer>
                {/* <div className="footer-main padding-top padding-bottom">
                    <div className="container">
                        <div className="footer-main-wrapper">
                            <a href="index.html" className="logo-footer">
                                <img src="assets/images/logo/logo-white-color-1.png" alt="" className="img-responsive" />
                            </a>
                            <div className="row">
                                <div className="col-2">
                                    <div className="col-md-3 col-xs-5">
                                        <div className="contact-us-widget widget">
                                            <div className="title-widget">contact us</div>
                                            <div className="content-widget">
                                                <div className="info-list">
                                                    <ul className="list-unstyled">
                                                        <li>
                                                            <i className="icons fa fa-map-marker"></i>
                                                            <a href="#" className="link">132, My Street, Kingston, New York 12401</a>
                                                        </li>
                                                        <li>
                                                            <i className="icons fa fa-phone"></i>
                                                            <a href="#" className="link">270 - 188 - 6026</a>
                                                        </li>
                                                        <li>
                                                            <i className="icons fa fa-envelope-o"></i>
                                                            <a href="#" className="link">domain@expooler.com</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="form-email">
                                                    <p className="text">Sign up for our mailing list to get latest updates and offers.</p>
                                                    <form action="index.html">
                                                        <div className="input-group">
                                                            <input type="text" placeholder="Email address" className="form-control form-email-widget" />
                                                            <span className="input-group-btn">
                                                                <button type="submit" className="btn-email">&#10004;</button>
                                                            </span>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-xs-3">
                                        <div className="booking-widget widget text-center">
                                            <div className="title-widget">book now</div>
                                            <div className="content-widget">
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <a href="#" className="link">Flights</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="link">Tours</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="link">Packages</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="link">Transfer</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="link">Car Rent</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="link">Cruises</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-xs-4">
                                        <div className="explore-widget widget">
                                            <div className="title-widget">explore</div>
                                            <div className="content-widget">
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <a href="#" className="link">Tour Singapore City</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="link">Tour Manila City</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="link">Tour New York City</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="link">Tour Sanghai City</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="link">Tour Hongkong City</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="link">Tour Tokyo City</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="col-md-2 col-xs-6">
                                        <div className="top-deals-widget widget">
                                            <div className="title-widget">top deals</div>
                                            <div className="content-widget">
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <a href="#" className="link">Tour Packages Singapore</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="link">Tour Packages Manila</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="link">Tour Packages New York</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="link">Tour Packages Sanghai</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="link">Tour Packages Hongkong</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="link">Tour Packages Tokyo</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-xs-6">
                                        <div className="destination-widget widget">
                                            <div className="title-widget">Destination</div>
                                            <div className="content-widget">
                                                <ul className="list-unstyled list-inline">
                                                    <li>
                                                        <a href="#" className="thumb">
                                                            <img src="assets/images/footer/gallery-01.jpg" alt="" className="img-responsive" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="thumb">
                                                            <img src="assets/images/footer/gallery-02.jpg" alt="" className="img-responsive" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="thumb">
                                                            <img src="assets/images/footer/gallery-03.jpg" alt="" className="img-responsive" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="thumb">
                                                            <img src="assets/images/footer/gallery-04.jpg" alt="" className="img-responsive" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="thumb">
                                                            <img src="assets/images/footer/gallery-05.jpg" alt="" className="img-responsive" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="thumb">
                                                            <img src="assets/images/footer/gallery-06.jpg" alt="" className="img-responsive" />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="hyperlink">
                    <div className="container">
                        {/* <div className="slide-logo-wrapper">
                            <div className="logo-item">
                                <a href="#" className="link">
                                    <img src="http://thaidobuy.com/Chiang_Mai_University.png" alt="" className="img-responsive" />
                                </a>
                            </div>
                            <div className="logo-item">
                                <a href="#" className="link">
                                    <img src="https://www.tat.or.th/images/logo/TAT-logo-header.svg" alt="" className="img-responsive" style={{ width: '100%' }} />
                                </a>
                            </div>
                            <div className="logo-item">
                                <a href="#" className="link">
                                    <img src="https://nakhonsi.mots.go.th/images/logo.png" alt="" className="img-responsive" />
                                </a>
                            </div>

                        </div> */}
                        <div className="social-footer" style={{ marginTop: '15px' }}>
                            <ul className="list-inline list-unstyled">
                                <li>
                                    <a href="#" className="link facebook">
                                        <i className="fa fa-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="link twitter">
                                        <i className="fa fa-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="link pinterest">
                                        <i className="fa fa-pinterest-p"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="link google">
                                        <i className="fa fa-google"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="name-company">&copy; Designed by CMU International College of Digital Innovation.</div>
                    </div>
                </div>
            </footer >

        );
    }
}