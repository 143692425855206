import React, { Component } from 'react';
import Templelist from '../../components/Temple'
import Header from '../../components/Header'
import Background from '../../images/temple.jpg'
import Footer from '../../components/Footer';

export default class Temple extends Component {
    render() {
        return (
            <div className="wrapper-content">
                <Header />
                <div id="wrapper-content">
                    <div className="main-content">
                        {/* <section className="page-banner blog" style={{ backgroundImage: "url(https://img.wongnai.com/p/1920x0/2019/01/13/be420520cd53476b92342ba4cb3f84b0.jpg)", backgroundPositionY: -200 }}> */}
                        <section className="page-banner blog" style={{ backgroundImage: "url(" + Background + ")" }}>
                            <div className="container">
                                <div className="page-title-wrapper">
                                    <div className="page-title-content">
                                        <ol className="breadcrumb">
                                            <li>
                                                <a href="./" className="link home">หน้าแรก</a>
                                            </li>
                                            <li className="active">
                                                <a href="#" className="link">วัดมรดกโลก</a>
                                            </li>
                                        </ol>
                                        <div className="clearfix"></div>
                                        <h2 className="captions">วัดมรดกโลก</h2>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Templelist />
                    </div>
                    <div id="back-top">
                        <a href="#top" className="link">
                            <i className="fa fa-angle-double-up"></i>
                        </a>
                    </div>
                </div>

                <Footer />
            </div>

        );
    }
}