import React from 'react';
import { convertImage } from '../../../Helpers';

const WidgetTrail = ({ trails, files, path }) => {

    // let scripts = [
    //     'js/pages/home-page.js',
    // ];
    // for (let i = 0; i < scripts.length; i++) {
    //     appendScript(path + scripts[i])
    // }

    console.log("Trail: " + trails)
    return (
        // <section className="tours padding-top padding-bottom" style={{ backgroundImage: "url(https://image.shutterstock.com/z/stock-photo-smiling-woman-traveler-with-backpack-holding-vintage-camera-on-holiday-in-thapae-gate-landmark-1473213161.jpg)", backgroundPosition: "center" }}>

        <section className="tours padding-top padding-bottom" style={{ background: 'none', backgroundColor: '#fff', backgroundPosition: "center" }}>
            <div className="container">
                <div className="tours-wrapper">
                    <div className="group-title">
                        <div className="sub-title">
                            <p className="text">Car Journey</p>
                            <i className="icons flaticon-people"></i>
                        </div>
                        <h2 className="main-title">5 เส้นทางท่องเที่ยวยอดฮิตตลอดกาล ใครไม่เคยไป ไม่มีวันรู้</h2>
                    </div>
                    <div className="tours-content margin-top70">
                        <div className="row">
                            {trails.map((item, index) => (
                                <div className="col-md-4" key={index}>
                                    <div className="tours-layout">
                                        <div className="image-wrapper">
                                            <a href={"./trail/" + item.id} className="link">
                                                <img src={files[index] != '' ? convertImage(path, files[index]) : 'assets/images/homepage/new-2.jpg'} alt="" className="img-responsive" style={{ height: '240px' }} />
                                            </a>
                                            <div className="title-wrapper">
                                                <a href={"./trail/" + item.id} className="title">{item.plan.name}</a>
                                                <i className="icons flaticon-circle"></i>
                                            </div>

                                        </div>
                                        <div className="content-wrapper">
                                            {/* <ul className="list-info list-inline list-unstyled">
                                    <li>
                                        <a href="#" className="link">
                                            <i className="icons fa fa-map-marker"></i>
                                            <span className="text number">{ item.store_id.length }</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="link">
                                            <i className="icons fa fa-heart"></i>
                                            <span className="text number">234</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="link">
                                            <i className="icons fa fa-comment"></i>
                                            <span className="text number">234</span>
                                        </a>
                                    </li>
                                </ul> */}
                                            <div className="content">

                                                <p className=" text-left">{(item.plan.description).replace(/(<([^>]+)>)/gi, "").substring(0, 150)}</p>
                                                <div className="group-btn-tours">
                                                    <a href={"./trail/" + item.id} className="btn btn-maincolor">สำรวจเส้นทาง</a>
                                                    {/* <a href="blog.html" className="right-btn">แบ่งปันเส้นทาง</a> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ))}

                        </div>

                        <a href="/trails/all" className="btn btn-transparent margin-top70">ดูทั้งหมด</a>
                    </div>
                </div>
            </div>
        </section >
    );
}

export default WidgetTrail;