import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { appendScript, stripTag } from '../../Helpers';
import { convertImage, convertMonth } from '../../Helpers'


export default class Eventlist extends Component {


    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            files: [],
            path: '',
            url: "https://chiangmaispirit.com/api/events"
        };
    }

    async componentDidMount() {

        appendScript(process.env.PUBLIC_URL + '/assets/js/pages/blog.js');


        await fetch(this.state.url)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result.data,
                        files: result.files,
                        path: result.path
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    })
                }
            )
    }

    clickPage = (getUrl) => {
        fetch(getUrl)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result.data,
                        files: result.files,
                        path: result.path
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    })
                }
            )
    }

    pagination = () => {
        let page = []

        // Outer loop to create parent
        for (let i = 1; i <= this.state.items.last_page; i++) {
            if (i === this.state.items.current_page) {
                page.push(<li key={i}><a className="btn-pagination active">{i}</a></li >)
            } else if (i == this.state.items.current_page + 1 || i == this.state.items.current_page - 1 || i == this.state.items.current_page + 2 || i == this.state.items.current_page - 2) {
                page.push(<li key={i}><a onClick={() => this.clickPage("https://chiangmaispirit.com/api/blogs?page=" + i)} className="btn-pagination">{i}</a></li >)
            }
        }
        return (
            <ul className="pagination" >
                {
                    this.state.items.prev_page_url !== null ?
                        (<li>
                            <a onClick={() => this.clickPage(this.state.items.prev_page_url)} aria-label="Previous" className="btn-pagination previous">
                                <span aria-hidden="true" className="fa fa-angle-left"></span>{this.state.current_page}
                            </a>
                        </li>)
                        : (<li></li>)
                }
                {page}
                {this.state.items.next_page_url !== null ?
                    (<li>
                        <a onClick={() => this.clickPage(this.state.items.next_page_url)} aria-label="Next" className="btn-pagination next">
                            <span aria-hidden="true" className="fa fa-angle-right"></span>{this.state.current_page}
                        </a>
                    </li>)
                    : (<li></li>)
                }
            </ul >
        )
    }

    render() {
        const { error, isLoaded, items, files, path } = this.state;
        console.log(JSON.stringify(files))
        if (error) {
            return <div> Error: {error.message} </div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (

                <div className="col-md-8 main-left blog-wrapper">
                    {items.data.map((item, index) => (
                        <div className="blog-post" key={item.name}>
                            <div className="blog-image">
                                <a href={"./event/" + item.id} className="link">
                                    <img src={convertImage(path, files[index])} alt="a car on a road" className="img-responsive" />
                                </a>
                            </div>
                            <div className="blog-content">
                                <div className="col-xs-2">
                                    <div className="row">
                                        <div className="date text">
                                            <h1 className="day">{item.start_datetime.substring(8, 10)}</h1>
                                            <div className="month">{convertMonth(item.start_datetime.substring(5, 7))}</div>
                                            <div className="year">{item.start_datetime.substring(0, 4)}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-10 content-wrapper">
                                    <a href={"./event/" + item.id} className="heading text">{item.name}</a>

                                    <p className="preview">{stripTag(item.detail).substring(0, 400)}</p>
                                    <a href={"./event/" + item.id}
                                        className="btn btn-gray btn-fit btn-capitalize">อ่านต่อ...</a>
                                </div>
                            </div>
                        </div>
                    ))}

                    <nav className="pagination-list margin-top70">
                        {this.pagination()}
                    </nav>

                </div>



            );
        }
    }
}