import React, { Component } from 'react';
import WidgetBooking from '../../components/Widget/Booking'
import WidgetAbout from '../../components/Widget/About'

import { appendScript } from '../../Helpers';

import '../../assets/main.css'
import WidgetTrail from '../../components/Widget/Trail';
import WidgetVideos from '../../components/Widget/Videos';
import WidgetPlaces from '../../components/Widget/Place';
import WidgetMerit from '../../components/Widget/Merit';
import WidgetNews from '../../components/Widget/News';
import Footer from '../../components/Footer';

export default class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {

            error: null,
            isLoaded: false,
            articles: [],
            news: [],
            trails: [],
            temples: [],
            filesNews: [],
            path: '',
        };
    }


    componentDidMount() {


        appendScript('/assets/js/pages/home-page.js')


        fetch("https://chiangmaispirit.com/api/home")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        articles: result.articles,
                        news: result.news,
                        filesNews: result.filesNews,
                        trails: result.trails,
                        fileTrails: result.fileTrails,
                        temples: result.temples,
                        fileTemples: result.fileTemples,
                        path: result.path
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    })
                }
            )
    }
    render() {
        const { error, isLoaded, articles, trails, fileTrails, temples, fileTemples, news, filesNews, path } = this.state;

        return (
            <div className="body-wrapper">
                <div className="wrapper-mobile-nav">
                    <div className="header-topbar">
                        <div className="topbar-search search-mobile">
                            <form className="search-form">
                                <div className="input-icon">
                                    <i className="btn-search fa fa-search"></i>
                                    <input type="text" placeholder="Search here..." className="form-control" />
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="header-main">
                        <div className="menu-mobile">
                            <ul className="nav-links nav navbar-nav">
                                <li className="dropdown">
                                    <a href="index.html" className="main-menu">
                                        <span className="text">Home</span>
                                    </a>
                                    <span className="icons-dropdown">
                                        <i className="fa fa-angle-down"></i>
                                    </span>
                                    <ul className="dropdown-menu dropdown-menu-1">
                                        <li>
                                            <a href="index.html" className="link-page">Homepage default</a>
                                        </li>
                                        <li>
                                            <a href="homepage-02.html" className="link-page">Homepage 02</a>
                                        </li>
                                        <li>
                                            <a href="homepage-03.html" className="link-page">Homepage 03</a>
                                        </li>
                                        <li>
                                            <a href="homepage-04.html" className="link-page">Homepage 04</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="about-us.html" className="main-menu">
                                        <span className="text">about</span>
                                    </a>
                                </li>
                                <li className="dropdown">
                                    <a href="tour-result.html" className="main-menu">
                                        <span className="text">Tour</span>
                                    </a>
                                    <span className="icons-dropdown">
                                        <i className="fa fa-angle-down"></i>
                                    </span>
                                    <ul className="dropdown-menu dropdown-menu-1">
                                        <li>
                                            <a href="tour-result.html" className="link-page">tour result</a>
                                        </li>
                                        <li>
                                            <a href="tour-view.html" className="link-page">tour view</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="dropdown">
                                    <a href="hotel-result.html" className="main-menu">
                                        <span className="text">packages</span>
                                    </a>
                                    <span className="icons-dropdown">
                                        <i className="fa fa-angle-down"></i>
                                    </span>
                                    <ul className="dropdown-menu dropdown-menu-1">
                                        <li>
                                            <a href="hotel-result.html" className="link-page">hotel result</a>
                                        </li>
                                        <li>
                                            <a href="hotel-view.html" className="link-page">hotel view</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="dropdown active">
                                    <a href="blog.html" className="main-menu">
                                        <span className="text">blog</span>
                                    </a>
                                    <span className="icons-dropdown">
                                        <i className="fa fa-angle-down"></i>
                                    </span>
                                    <ul className="dropdown-menu dropdown-menu-1">
                                        <li>
                                            <a href="blog.html" className="link-page">blog list</a>
                                        </li>
                                        <li>
                                            <a href="blog-detail.html" className="link-page">blog detail</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="dropdown">
                                    <a href="car-rent-result.html" className="main-menu">
                                        <span className="text">page</span>
                                    </a>
                                    <span className="icons-dropdown">
                                        <i className="fa fa-angle-down"></i>
                                    </span>
                                    <ul className="dropdown-menu dropdown-menu-1">
                                        <li>
                                            <a href="car-rent-result.html" className="link-page">car rent result</a>
                                        </li>
                                        <li>
                                            <a href="cruises-result.html" className="link-page">cruises result</a>
                                        </li>
                                        <li>
                                            <a href="404.html" className="link-page">page 404</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="contact.html" className="main-menu">
                                        <span className="text">contact</span>
                                    </a>
                                </li>
                            </ul>
                            <ul className="list-unstyled list-inline login-widget">
                                <li>
                                    <a href="#" className="item">login</a>
                                </li>
                                <li>
                                    <a href="#" className="item">register</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="wrapper-content">
                    <header>
                        <div className="bg-transparent header-01">
                            <div className="header-main">
                                <div className="container">
                                    <div className="header-main-wrapper">
                                        <div className="hamburger-menu">
                                            <div className="hamburger-menu-wrapper">
                                                <div className="icons"></div>
                                            </div>
                                        </div>
                                        <div className="navbar-header">
                                            <div className="logo">
                                                <a href="index.html" className="header-logo">
                                                    <img src="../../images/Smallogo.gif" alt="" style={{ width: '55px' }} />
                                                </a>
                                            </div>
                                        </div>
                                        <nav className="navigation">
                                            <ul className="nav-links nav navbar-nav">
                                                <li>
                                                    <a href="/" className="main-menu">
                                                        <span className="text">หน้าแรก</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/temples" className="main-menu">
                                                        <span className="text">วัดมรดกโลก</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/trails/all" className="main-menu">
                                                        <span className="text">เส้นทางท่องเที่ยว</span>
                                                    </a>

                                                </li>
                                                <li>
                                                    <a href="/bank" className="main-menu">
                                                        <span className="text">ธนาคารไข่</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/srisupan" className="main-menu">
                                                        <span className="text">ทำบุญออนไลน์</span>
                                                    </a>
                                                </li>

                                                <li className="button-search">
                                                    <p className="main-menu">
                                                        <i className="fa fa-search"></i>
                                                    </p>
                                                </li>
                                            </ul>

                                            <div className="nav-search hide">
                                                <form>
                                                    <input type="text" placeholder="ค้นหาที่พัก ที่กิน ที่เที่ยว" className="searchbox" />
                                                    <button type="submit" className="searchbutton fa fa-search"></button>
                                                </form>
                                            </div>
                                        </nav>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div id="wrapper-content">
                        <div className="main-content">
                            <section className="page-banner homepage-default" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/bg.jpg" + ")" }}>
                                <div className="container">
                                    <div className="homepage-banner-warpper">
                                        <div className="homepage-banner-content">
                                            <div className="group-title">
                                                <h1 className="title">CM Journey</h1>
                                                <p className="text">ไปไหนไปกัน เจอนั่น เจอนี่
                                                <span className="boder"></span>
                                                </p>
                                            </div>
                                            {/* <div className="group-btn">
                                                <a href="#" data-hover="CLICK ME" className="btn-click">
                                                    <span className="text">go explore now</span>
                                                    <span className="icons fa fa-long-arrow-right"></span>
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <WidgetBooking />
                            {/* {(articles != '' ?
                                <WidgetBooking articles={articles} path={path} />
                                : (<h1>Loading</h1>)
                            )} */}


                            {(trails.length > 0 ?
                                (<WidgetTrail trails={trails} path={path} files={fileTrails} />)
                                : (<h1>Loading</h1>)
                            )}

                            <WidgetAbout />

                            {(temples.length > 0 ?
                                (<WidgetPlaces temples={temples} path={path} files={fileTemples} />)
                                : (<h1>Loading</h1>)
                            )}

                            {/* <WidgetMerit /> */}

                            {(trails.length > 0 ?
                                (<WidgetNews items={news} path={path} files={filesNews} />)
                                : (<h1>Loading</h1>)
                            )}

                            {/* <WidgetVideos /> */}

                        </div>
                    </div>
                    <Footer />
                </div>
                <div className="theme-setting">
                </div>
            </div>
        );
    }
}

