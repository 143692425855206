import React, { Component } from 'react';
import Header from '../../components/Header'
import { convertImage } from '../../Helpers'
import Footer from '../../components/Footer';


export default class Projects extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            files: [],
            path: '',
        };
    }

    componentDidMount() {

        fetch("https://chiangmaispirit.com/api/temples/18" + this.id)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result.data,
                        files: result.file,
                        path: result.path
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    })
                }
            )
    }

    render() {
        let id = this.props.match.params.id;
        const { error, isLoaded, items, files, path } = this.state;
        // console.log('file: '+files)
        if (files.length > 0) {
            return (
                <div className="wrapper-content">
                    <Header />
                    <div id="wrapper-content">
                        <div className="main-content">
                            <section className="page-banner blog" style={{ backgroundImage: "url(" + convertImage(path, files) + ")" }}>
                                <div className="container">
                                    <div className="page-title-wrapper">
                                        <div className="page-title-content">
                                            <ol className="breadcrumb">
                                                <li>
                                                    <a href="index.html" className="link home">หน้าแรก</a>
                                                </li>
                                                <li className="active">
                                                    <a href="#" className="link">เที่ยววัดเชียงใหม่</a>
                                                </li>
                                            </ol>
                                            <div className="clearfix"></div>
                                            <h2 className="captions" style={{ maxWidth: "100%" }}>{items.name}</h2>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {/* <Templeview id={id} data={items} files={files} path={path} /> */}
                        </div>
                        <div id="back-top">
                            <a href="#top" className="link">
                                <i className="fa fa-angle-double-up"></i>
                            </a>
                        </div>
                    </div>

                    <Footer />
                </div>

            );
        } else {
            return (<h1 className="text-center padding-top">Loading</h1>)
        }
    }
}