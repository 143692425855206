import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { appendScript, stripTag } from '../../Helpers';
import SidebarSearch from '../Sidebar/Search';
import SidebarTemples from '../Sidebar/Temples';
import { convertImage, convertMonth } from '../../Helpers'


export default class Templelist extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            files: [],
            path: '',
            url: "https://chiangmaispirit.com/api/temples"
        };
    }

    async componentDidMount() {

        appendScript(process.env.PUBLIC_URL + '/assets/js/pages/blog.js');


        await fetch(this.state.url)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result.data,
                        files: result.file,
                        path: result.path
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    })
                }
            )
    }

    clickPage = (getUrl) => {
        fetch(getUrl)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result.data,
                        path: result.path
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    })
                }
            )
    }

    pagination = () => {
        let page = []

        // Outer loop to create parent
        for (let i = 1; i <= this.state.items.last_page; i++) {
            if (i === this.state.items.current_page) {
                page.push(<li key={i}><a className="btn-pagination active">{i}</a></li >)
            } else if (i == this.state.items.current_page + 1 || i == this.state.items.current_page - 1 || i == this.state.items.current_page + 2 || i == this.state.items.current_page - 2) {
                page.push(<li key={i}><a onClick={() => this.clickPage("https://chiangmaispirit.com/api/temples?page=" + i)} className="btn-pagination">{i}</a></li >)
            }
        }
        return (
            <ul className="pagination" >
                {
                    this.state.items.prev_page_url !== null ?
                        (<li>
                            <a onClick={() => this.clickPage(this.state.items.prev_page_url)} aria-label="Previous" className="btn-pagination previous">
                                <span aria-hidden="true" className="fa fa-angle-left"></span>{this.state.current_page}
                            </a>
                        </li>)
                        : (<li></li>)
                }
                {page}
                {this.state.items.next_page_url !== null ?
                    (<li>
                        <a onClick={() => this.clickPage(this.state.items.next_page_url)} aria-label="Next" className="btn-pagination next">
                            <span aria-hidden="true" className="fa fa-angle-right"></span>{this.state.current_page}
                        </a>
                    </li>)
                    : (<li></li>)
                }
            </ul >
        )
    }


    render() {
        const { error, isLoaded, items, files, path } = this.state;
        console.log(files)
        if (error) {
            return <div> Error: {error.message} </div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (

                <section className="page-main padding-top padding-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 main-left blog-wrapper">
                                {items.data.map((item, index) => (
                                    <div className="blog-post" key={item.name}>
                                        <div className="blog-image">
                                            <a href={"./temples/" + item.id} className="link">
                                                <img src={convertImage(path, files[index])} alt="a car on a road" className="img-responsive" />
                                            </a>
                                        </div>
                                        <div className="blog-content">
                                            <div className="col-xs-2">
                                                <div className="row">
                                                    <div className="date text">
                                                        <h1 className="day">{item.updated_at.substring(8, 10)}</h1>
                                                        <div className="month">{convertMonth(item.updated_at.substring(5, 7))}</div>
                                                        <div className="year">{item.updated_at.substring(0, 4)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-10 content-wrapper">
                                                <a href={"./temples/" + item.id} className="heading text">{item.name}</a>

                                                <p className="preview">{item.overview != null ? stripTag(item.overview).substring(0, 400) : ''}</p>
                                                <a href={"./temples/" + item.id}
                                                    className="btn btn-gray btn-fit btn-capitalize">อ่านต่อ...</a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {/* <div className="blog-post">
                                        <div className="blog-video">
                                            <div className="video-thumbnail">
                                                <div className="video-bg">
                                                    <img src="assets/images/blog/blog-video-poster-1.jpg" alt="A traveler standing on a rock" className="img-responsive" />
                                                </div>
                                                <div className="video-button-play">
                                                    <i className="icons fa fa-play"></i>
                                                </div>
                                                <div className="video-button-close"></div>
                                                <iframe src="https://www.youtube.com/embed/moOosWuoDyA?rel=0" allowfullscreen="allowfullscreen" className="video-embed"></iframe>
                                            </div>
                                        </div>
                                        <div className="blog-content">
                                            <div className="col-xs-2">
                                                <div className="row">
                                                    <div className="date">
                                                        <h1 className="day">07</h1>
                                                        <div className="month">JAN</div>
                                                        <div className="year">2016</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-10 content-wrapper">
                                                <a href="#" className="heading">Many people limit themselves what they think they can do.</a>
                                                <h5 className="meta-info">Posted By :
                                                    <span>John Smith</span>
                                                    <span className="sep">/</span>
                                                    <span className="view-count fa-custom">56</span>
                                                    <span className="comment-count fa-custom">239</span>
                                                </h5>
                                                <p className="preview">Donec sed odio dui. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Sed posuere consectetur est at lobortis. Nulla vitae elit libero, a pharetra augue.
                                                    Donec ullamcorper nulla non metus auctor fringilla. Donec id elit non mi porta gravida at eget metus. Fusce dapibus, tellus ac cursus , tortor mauris condimentum nibh, ut...</p>
                                                <a href="#"
                                                    className="btn btn-gray btn-fit btn-capitalize">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog-post">
                                        <div className="blog-content">
                                            <div className="col-xs-2">
                                                <div className="row">
                                                    <div className="date">
                                                        <h1 className="day">06</h1>
                                                        <div className="month">DEC</div>
                                                        <div className="year">2016</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-10 quote-content-wrapper content-wrapper">
                                                <blockquote className="quote-wrapper fa-custom">
                                                    <a href="#" className="quote">Donec sed odio dui. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Sed posuere consectetur est at lobortis a.</a>
                                                    <footer>
                                                        <span className="author">Amber Ivy</span>
                                                        <span className="company">(Grancie Company)</span>
                                                    </footer>
                                                </blockquote>
                                                <p className="preview">Donec sed odio dui. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Sed posuere consectetur est at lobortis. Nulla vitae elit libero, a pharetra augue.
                                                    Donec er nulla non metus auctor fringilla est non commodo luctus.</p>
                                                <a href="#" className="btn btn-gray btn-fit btn-capitalize">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog-post"> 
                                        <div className="blog-image">
                                            <a href="#" className="link">
                                                <img src="assets/images/blog/blog-image-1.jpg" alt="a car on a road" className="img-responsive" />
                                            </a>
                                        </div>
                                        <div className="blog-content">
                                            <div className="col-xs-2">
                                                <div className="row">
                                                    <div className="date">
                                                        <h1 className="day">07</h1>
                                                        <div className="month">JAN</div>
                                                        <div className="year">2016</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-10 content-wrapper">
                                                <a href="#" className="heading">Many people limit themselves what they think they can do.</a>
                                                <h5 className="meta-info">Posted By :
                                                    <span>John Smith</span>
                                                    <span className="sep">/</span>
                                                    <span className="view-count fa-custom">56</span>
                                                    <span className="comment-count fa-custom">239</span>
                                                </h5>
                                                <p className="preview">Donec sed odio dui. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Sed posuere consectetur est at lobortis. Nulla vitae elit libero, a pharetra augue.
                                                    Donec ullamcorper nulla non metus auctor fringilla. Donec id elit non mi porta gravida at eget metus. Fusce dapibus, tellus ac cursus , tortor mauris condimentum nibh, ut...</p>
                                                <a href="#"
                                                    className="btn btn-gray btn-fit btn-capitalize">Read More</a>
                                            </div>
                                        </div>
                                    </div>*/}
                                <nav className="pagination-list margin-top70">
                                    <nav className="pagination-list margin-top70">
                                        {this.pagination()}
                                    </nav>
                                </nav>
                            </div>
                            <div className="col-md-4 sidebar-widget">
                                <SidebarSearch />
                                <SidebarTemples />
                            </div>
                        </div>
                    </div>
                </section>


            );
        }
    }
}