import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SidebarSearch from '../Search';

function convertImage(p, f) {
    var randomItem = f[Math.floor(Math.random() * f.length)].replace('/home/cmspirit/domains/chiangmaispirit.com/public_html/public', '')
    return p + randomItem;
}

function convertMonth(i) {
    switch (i) {
        case '01': return 'ม.ค'; break;
        case '02': return 'ก.พ'; break;
        case '03': return 'มี.ค'; break;
        case '04': return 'เม.ย'; break;
        case '05': return 'พ.ค'; break;
        case '06': return 'มิ.ย'; break;
        case '07': return 'ก.ค'; break;
        case '08': return 'ส.ต'; break;
        case '09': return 'ก.ย'; break;
        case '10': return 'ต.ย'; break;
        case '11': return 'พ.ย'; break;
        case '12': return 'ธ.ค'; break;
        default: return null;
    }
}

export default class SidebarPlace extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            files: [],
            path: '',
        };
    }

    // componentDidMount() {
    //     fetch("https://chiangmaispirit.com/api/temples")
    //         .then(res => res.json())
    //         .then(
    //             (result) => {
    //                 this.setState({
    //                     isLoaded: true,
    //                     items: result.data,
    //                     files: result.file,
    //                     path: result.path
    //                 });
    //             },
    //             (error) => {
    //                 this.setState({
    //                     isLoaded: true,
    //                     error
    //                 })
    //             }
    //         )
    // }

    render() {
        // const { error, isLoaded, items, files, path } = this.state;
        // console.log(files)
        // if (error) {
        //     return <div> Error: {error.message} </div>;
        // } else if (!isLoaded) {
        //     return <div>Loading...</div>;
        // } else {
        return (
            <div className="col-2">
                <div className="col-1">
                    <div className="recent-post-widget widget">

                        <div className="title-widget" style={{ borderBottom: "2px solid #FF9800", backgroundColor: "#f1c50e" }}>
                            <div className="title" style={{ borderBottom: "2px solid #FF9800" }}>เส้นทางสายบุญ</div>
                        </div>
                        <div className="content-widget">
                            <div className="recent-post-list">
                                <div className="single-widget-item">
                                    <div className="single-recent-post-widget">
                                        <a href="#" className="thumb img-wrapper">
                                            <img src="https://chiangmaispirit.com/Uploads/Stores/18/DSC03816.jpg" alt="recent post picture 1" />
                                        </a>
                                        <div className="post-info">
                                            <div className="meta-info">
                                                <span>Aug 18, 2016</span>
                                                <span className="sep">/</span>
                                                <span className="fa-custom view-count">56</span>
                                                <span className="fa-custom comment-count">239</span>
                                            </div>
                                            <div className="single-rp-preview">วัดพระสิงห์ ตั้งอยู่ด้านทิศตะวันตกของเมืองเก่า เป็นที่ประดิษฐานพระพุทธสิหิงค์ ซี่งเป็นที่นับถือของ คนเชียงใหม่ </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-widget-item">
                                    <div className="single-recent-post-widget">
                                        <a href="#" className="thumb img-wrapper">
                                            <img src="https://chiangmaispirit.com/Uploads/Stores/19/_DSC9512.jpg" alt="recent post picture 1" />
                                        </a>
                                        <div className="post-info">
                                            <div className="meta-info">
                                                <span>Aug 18, 2016</span>
                                                <span className="sep">/</span>
                                                <span className="fa-custom view-count">56</span>
                                                <span className="fa-custom comment-count">239</span>
                                            </div>
                                            <div className="single-rp-preview">วัดเจดีย์หลวง อยู่ไม่ไกลจากวัดพระสิงห์ เป็นวัดที่จะต้องไปชมวัดนี้มีเจดีย์ยอดหักจากแผ่นดินไหวในปี พ.ศ. 2088  </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-widget-item">
                                    <div className="single-recent-post-widget">
                                        <a href="#" className="thumb img-wrapper">
                                            <img src="https://chiangmaispirit.com/Uploads/Stores/20/_DSC9395.jpg" alt="recent post picture 1" />
                                        </a>
                                        <div className="post-info">
                                            <div className="meta-info">
                                                <span>Aug 18, 2016</span>
                                                <span className="sep">/</span>
                                                <span className="fa-custom view-count">56</span>
                                                <span className="fa-custom comment-count">239</span>
                                            </div>
                                            <div className="single-rp-preview">วัดหมื่นเงินกอง สร้างในสมัยพระเจ้ากือนา โดยหมื่นเงินกอง (เป็นขุนคลังในสมัยนั้น) ในระหว่างพ.ศ 1882-1916</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-1">
                    <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fcnx.spirit%2F&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1509268459376115" width="340" height="500" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </div>
            </div>


        );
    }
    // }
}