import React, { Component } from 'react';
import Templelist from '../../components/Temple'
import Header from '../../components/Header'
import Background from '../../images/temple.jpg'
import Footer from '../../components/Footer';

export default class Bank extends Component {
    render() {
        let bg = '../../images/FirstPage99.jpg';

        return (
            <div className="wrapper-content">
                <Header />
                <div id="wrapper-content">
                    <div className="main-content">
                        {/* <section className="page-banner blog" style={{ backgroundImage: "url(https://img.wongnai.com/p/1920x0/2019/01/13/be420520cd53476b92342ba4cb3f84b0.jpg)", backgroundPositionY: -200 }}> */}
                        <section className="page-banner blog" style={{ backgroundImage: "url(" + bg + ")" }}>
                            <div className="container">
                                <div className="page-title-wrapper">
                                    <div className="page-title-content">
                                        <ol className="breadcrumb">
                                            <li>
                                                <a href="./" className="link home">หน้าแรก</a>
                                            </li>
                                            <li className="active">
                                                <a href="#" className="link">ธนาคารไข่</a>
                                            </li>
                                        </ol>
                                        <div className="clearfix"></div>
                                        <h2 className="captions">ธนาคารไข่สิริยศวดี</h2>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section class="about-us layout-2 padding-top padding-bottom about-us-4">
                            <div class="container">
                                <div class="row">
                                    <div class="wrapper-contact-style">
                                        <div className="col-md-6">
                                            <p class="">
                                                เป็นโครงการที่เป็นความร่วมมือระหว่างวิทยาลัยนานาชาตินวัตกรรมดิจิตอลกับวัดศรีสุพรรณ
                                                โดยทุนวิจัยของกระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม (อว.)
                                            </p>
                                            <p class="">
                                                การดำเนินงานโครงการของวิทยาลัยนานาชาตินวัตกรรมดิจิทัล
                                                ที่เป็นการต่อยอดจากโครงการเลี้ยงไก่ประดู่หางดำที่ได้รับทุนสนับสนุนจากสำนักงานกองทุนสนับสนุนการวิจัย (สกว.)
                                                โดย วัดศรีสุพรรณจะเป็นศูนย์กลางสนับสนุนการประกอบอาชีพเสริมแก่ชาวบ้านที่ได้รับความเดือดร้อนจากสถานการณ์โรคระบาดไวรัสโคโรน่า (โควิด-19)
                                                ซึ่งจะมีการดำเนินการอย่างเต็มรูปแบบตั้งแต่เดือนตุลาคม พ.ศ.2563
                                            </p>
                                            <p class="">

                                                “ธนาคารไข่สิริยศวดี” - การชำระหนี้ด้วยไข่ไก่ และกำไรที่คืนสู่ชุมชน
                                                ธนาคารไข่สิริยศวดี โครงการได้จัดตั้งระบบการเงินระดับจุลภาค (Microfinance)
                                                โดยมีการปล่อยสินเชื่อขนาดเล็กให้แก่คนยากจนที่ได้รับผลกระทบจากโรคระบาด
                                                หรือเกษตรกรที่ไม่สามารถเข้าถึงสินเชื่อของสถาบันการเงินปกติได้ และจัดตั้งทุนสนับสนุนงบประมาณให้แก่ผู้เข้าร่วมโครงการ
                                                ซึ่งชาวบ้านจะได้รับแม่ไก่พันธ์ไข่อายุ 16 สัปดาห์ จำนวน 200 ตัว มูลค่า 34,000 บาทและเงินสนับสนุนอีกจำนวน 16,000 บาท
                                                ในการสร้างโรงเรือน และซื้อวัสดุอุปกรณ์สำหรับเลี้ยงไก่ไข่ พร้อมได้รับการอบรมความรู้การเลี้ยงไก่ไข่แบบปล่อยอย่างมีคุณภาพตามมาตรฐานกรมปศุสัตว์จาก
                                                ผู้ช่วยศาสตราจารย์นายสัตวแพทย์ ดร.สุวิทย์ โชตินันท์ ผู้เชี่ยวชาญด้านสัตว์ปีก คณะสัตวแพทย์ มหาวิทยาลัยเชียงใหม่
                                                เพื่อสร้างรายได้จากอาชีพเสริมประมาณวันละ 200 บาท ในระยะเวลา 2 ปี ตลอดจนถึงมีไข่ไก่เพื่ออุปโภคบริโภคในครัวเรือนของตนเอง
                                                โดยมีเงื่อนไขว่าผู้เข้าร่วมโครงการจะต้องตั้งใจดูแลไก่ไข่อย่างเต็มที่และชำระหนี้สินในรูปแบบของแผงไข่ วันละ 1 แผง (เฉลี่ยวันละ 30 ฟอง)
                                                หรือ คำนวณเป็นเงินวันละ 120 บาท เป็นเวลา 500 วัน (หรือรวมจำนวนไข่ไก่ต้องคืนแก่วัด 15,000 ฟอง)
 </p>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="text-center">
                                                <img src="images/Egg_Bank_Logo.png" alt="" style={{ width: '40%' }} />
                                            </div>
                                            <p class="" style={{ marginTop: '30px' }}>
                                                สำหรับชื่อของ “ไข่ไก่สิริยศวดี” นั้น มาจากชื่อของ พระนางสิริยศวดีเทวี หรือ นางโป่งน้อย
                                                พระมเหสีในยุคสมัยพญายอดเชียงรายแห่งอาณาจักรล้านนนาองค์ที่ 10
                                                และยังเป็นพระราชมารดาของพระเมืองแก้วหนึ่งในกษัตริย์แห่งราชวงศ์เม็งรายองค์ที่ 11
                                                ตลอดพระชนม์ชีพพระองค์มีความศรัทธาในพุทธศาสนา จึงได้สร้างวัดวาอารามและทรงบำนุบำรุงพระศาสนาเสมอมา
                                                หนึ่งในวัดที่ถูกสร้างนั่นคือวัดศรีสุพรรณ ในปี พ.ศ. 2043
                                                เพื่อเป็นการให้เกียรติประวัติและการระลึกถึงพระนางสิริยศวดีเทวีในฐานะผู้ก่อตั้งวัดศรีสุพรรณ
                                                จึงได้จัดตั้งชื่อแบรนด์เป็นชื่อของพระองค์
                                                </p>
                                        </div>
                                        {/* <div class="col-lg-3 col-lg-offset-1 col-md-6 padding-top">
                                            <img src="images/Egg_Bank_Logo.png" alt="" className="img-responsive" />

                                        </div>
                                        <div class="col-lg-6 col-lg-offset-1 col-md-6">
                                            <h3 class="title-style-2 padding-top">ไข่ไก่สิริยศวดี</h3>
                                            <div class="about-us-wrapper">
                                                <p class="text">
                                                    สำหรับชื่อของ “ไข่ไก่สิริยศวดี” นั้น มาจากชื่อของ พระนางสิริยศวดีเทวี หรือ นางโป่งน้อย
                                                    พระมเหสีในยุคสมัยพญายอดเชียงรายแห่งอาณาจักรล้านนนาองค์ที่ 10
                                                    และยังเป็นพระราชมารดาของพระเมืองแก้วหนึ่งในกษัตริย์แห่งราชวงศ์เม็งรายองค์ที่ 11
                                                    ตลอดพระชนม์ชีพพระองค์มีความศรัทธาในพุทธศาสนา จึงได้สร้างวัดวาอารามและทรงบำนุบำรุงพระศาสนาเสมอมา
                                                    หนึ่งในวัดที่ถูกสร้างนั่นคือวัดศรีสุพรรณ ในปี พ.ศ. 2043
                                                    เพื่อเป็นการให้เกียรติประวัติและการระลึกถึงพระนางสิริยศวดีเทวีในฐานะผู้ก่อตั้งวัดศรีสุพรรณ
                                                    จึงได้จัดตั้งชื่อแบรนด์เป็นชื่อของพระองค์
                                                </p>

                                            </div>
                                        </div> */}

                                        <div className="col-md-12 padding-top">
                                            <h3 className="title-style-2">
                                                ภายใต้แบรนด์ ‘ไข่ไก่สิริยสวดี’ จากกลุ่มชาวบ้านและเกษตรกรในโครงการ<br />
                                                ไข่ไก่จะถูกจำหน่ายและทำบุญในส่วนต่างๆ ของวัดศรีสุพรรณ โดยแบ่งออกเป็น 3 ส่วน คือ
                                                </h3>
                                            <div className=" row text-center" style={{ paddingTop: '30px' }}>
                                                <div className="col-md-4">
                                                    <p style={{ paddingBottom: '30px' }}><img src="https://www.flaticon.com/svg/static/icons/svg/3408/3408993.svg" style={{ width: '128px' }} /></p>
                                                    <h4>โรงทานวัดศรีสุพรรณ :</h4>
                                                        ไข่ไก่ที่ได้รับจากชาวบ้านและเกษตรกรในรูปแบบการชำระหนี้สิน ประมาณอย่างต่ำ 480 ​ฟองต่อวัน จะถูกนำมาสรรค์สร้างเป็นเมนูโรงทานต่างๆ เพื่อแจกจ่ายให้แก่ผู้ทุกข์ยาก ผู้ที่ขาดรายได้ ​โดยเฉพาะกลุ่มเด็กและแม่ที่กำลังตั้งครรภ์จะได้รับโปรตีนจากไข่ไก่อารมณ์ดี ที่ปลอดสาร และยังลด​ภาวะการก่อมะเร็งได้ถึง 20%
                                                </div>
                                                <div className="col-md-4">
                                                    <p style={{ paddingBottom: '30px' }}><img src="https://www.flaticon.com/svg/static/icons/svg/3410/3410619.svg" style={{ width: '128px' }} /></p>
                                                    <h4>การจัดตั้งทำบุญออนไลน์ :</h4>
                                                        ผู้มีจิตศรัทธาสามารถร่วมทำบุญกับทางวัดศรีสุพรรณจำนวน 1,000 บาทขึ้นไปผ่านช่องทาง​ออนไลน์ หรือ สแกน QR-Code ไข่ไก่ที่ได้จากการทำบุญจะถูกนำไปสร้างโรงทานแก่ผู้ยากไร้ ผู้​​บริจาคจะได้รับใบอนุโมทนาบุญจากทางวัดศรีสุพรรณเพื่อนำไปลดหย่อนภาษีตามกฏหมาย
                                                </div>
                                                <div className="col-md-4">
                                                    <p style={{ paddingBottom: '30px' }}><img src="https://www.flaticon.com/svg/static/icons/svg/1622/1622392.svg" style={{ width: '128px' }} /></p>
                                                    <h4>ทำบุญด้วยไข่ไก่ถวายพระเจ้า 7 ตื้อ :</h4>
                                                        องค์พระเจ้า 7 ตื้อที่ประดิษฐานในอุโบสถเงินแห่งนี้ มีตำนานเล่าขานว่า พระประธานองค์นี้​เคยแสดงพุทธปฏิหาริย์ลงสรงน้ำในสระข้างอุโบสถอยู่เป็นประจำ ประทานความสำเร็จแก่ผู้ที่มา​​อธิษฐานกราบไหว้เสมอ จึงกลายเป็นพระประธานที่เคารพสักการะของชาวบ้านวัวลายและ​​ประชาชนทั่วไป
                                                </div>
                                            </div>

                                        </div>

                                        <div className="col-md-12 padding-top">
                                            <img src="images/banner-egg.jpg" className="img-responsive" />
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <Templelist /> */}
                    </div>
                    <div id="back-top">
                        <a href="#top" className="link">
                            <i className="fa fa-angle-double-up"></i>
                        </a>
                    </div>
                </div>

                <Footer />
            </div>

        );
    }
}