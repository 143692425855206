import React from 'react';
import { convertImage, stripTag } from '../../../Helpers';

const WidgetPlaces = ({ temples, files, path }) => {
    console.log("Place: " + temples)
    return (
        // <section className="hotels padding-top padding-bottom" style={{ backgroundImage:"url(https://image.shutterstock.com/z/stock-photo-tuk-tuk-for-passenger-cars-to-go-sightseeing-in-chiang-mai-304587893.jpg)", backgroundPosition:"center" }}>
        <section className="hotels padding-top padding-bottom" style={{ background: 'none', backgroundColor: '#FFEB3B', backgroundPosition: "center" }}>

            <div className="container">
                <div className="hotels-wrapper">
                    <div className="group-title">
                        <div className="sub-title">
                            <p className="text">World Heritage Temples</p>
                            <i className="icons flaticon-people" style={{ color: '#434a54' }}></i>
                        </div>
                        <h2 className="main-title">ชวนชมวัดมรดกโลก ความล้ำค่าทางวัฒนธรรมที่ประเมินค่าไม่ได้</h2>
                    </div>
                    <div className="hotels-content margin-top70">
                        <div className="row hotels-list">
                            {temples.map((item, index) => (
                                <div className="col-sm-6" key={index}>
                                    <div className="hotels-layout">
                                        <div className="image-wrapper">
                                            <a href={'./temples/' + item.id} className="link">
                                                <img src={files[index] !== '' ? convertImage(path, files[index]) : 'assets/images/homepage/new-2.jpg'} alt="" className="img-responsive" />
                                            </a>
                                            <div className="title-wrapper">
                                                <a href="hotel-view.html" className="title">{item.name}</a>
                                                <div title="Rated {item.rating_admin}.00 out of 5" className="star-rating">
                                                    <span className="width-{item.rating_admin * 20}">
                                                        <strong className="rating">{item.rating_admin}.00</strong> out of 5</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="content-wrapper">
                                            <div className="content" style={{ padding: "0 15px" }}>
                                                {/* <div className="title">
                                            <div className="price">
                                                <sup>$</sup>
                                                <span className="number">30</span>
                                            </div>
                                            <p className="for-price">for person per night</p>
                                        </div> */}
                                                <p className="text-left" style={{ marginBottom: '15px' }}>{item.detail !== '' ? stripTag(item.description).substring(0, 200) + ' ...' : '-'}</p>
                                                <div className="group-btn-tours">
                                                    <a href={'./temples/' + item.id} className="left-btn">ดูรายละเอียด</a>
                                                </div>
                                            </div>
                                            {/* <ul className="list-info list-unstyled">
                                                <li>
                                                    <a href="#" className="link">
                                                        <i className="icons hidden-icon fa fa-eye"></i>
                                                        <span className="number">234</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="link">
                                                        <i className="icons hidden-icon fa fa-heart"></i>
                                                        <span className="number">156</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="link">
                                                        <i className="icons hidden-icon fa fa-comment"></i>
                                                        <span className="number">19</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href=")" className="link">
                                                        <i className="icons fa fa-share-alt"></i>
                                                    </a>
                                                    <ul className="share-social-list">
                                                        <li>
                                                            <a href="#" className="link-social">
                                                                <i className="icons fa fa-facebook"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#" className="link-social">
                                                                <i className="icons fa fa-google-plus"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#" className="link-social">
                                                                <i className="icons fa fa-twitter"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a href="#" className="link">
                                                        <i className="icons fa fa-map-marker"></i>
                                                    </a>
                                                </li>
                                            </ul> */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <a href={'./temples'} className="btn btn-transparent margin-top70">ดูทั้งหมด</a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WidgetPlaces;
// export default class WidgetPlaces extends Component {
//     constructor(props) {
//         super(props);
//     }

//      componentDidMount() {

//     }

//     render() {
//         console.log("this place: " + this.props.temples);


//     }
// }