import React from 'react';

import SidebarSearch from '../Sidebar/Search';
import SidebarTemples from '../Sidebar/Temples';
import { convertImage, stripTag } from '../../Helpers';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow,
} from "react-google-maps";

const MapWithAMarker = withScriptjs(withGoogleMap(props =>
    <GoogleMap
        defaultZoom={14}
        defaultCenter={{ lat: 18.7883439, lng: 98.98530079999999 }}
    >
        {console.log('data : ' + JSON.stringify(props.data))}
        {
            props.data.map((item, k) =>
                <Marker key={k}
                    position={{ lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) }}
                >
                    <InfoWindow onCloseClick={props.onToggleOpen}>
                        <div>
                            {item.name}
                        </div>
                    </InfoWindow>
                </Marker>
            )
        }
    </GoogleMap >
));


const Trailsview = ({ items, files, storeObj, path }) => {


    if (storeObj) {
        return (

            <section className="page-main padding-top padding-bottom">

                <div className="container">
                    <div className="row">
                        <div className="col-md-8 main-left">
                            <div className="item-blog-detail">
                                <div className="blog-post blog-text">
                                    {/* <div className="blog-image">
                                        <a href="#" className="link">
                                            <img src={convertImage(path, files)} alt="car on a road" className="img-responsive" />
                                        </a>
                                    </div> */}
                                    <div className="blog-content" style={{ paddingTop: "0px" }}>
                                        <div className="row">

                                            <div className="col-xs-12 blog-text">
                                                <div className="blog-descritption" dangerouslySetInnerHTML={{ __html: items.description.replace(/[/]photos[/]shares/g, 'https://chiangmaispirit.com/photos/shares') }}></div>

                                                <MapWithAMarker googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAL_eMAgCtTjJEBWQDpwJGBF1OuXbYUR9s&v=3.exp&libraries=geometry,drawing,places"
                                                    loadingElement={<div style={{ height: `100%` }} />}
                                                    containerElement={<div style={{ height: `400px` }} />}
                                                    mapElement={<div style={{ height: `100%` }} />}
                                                    data={storeObj}
                                                />
                                                <div className="row">
                                                    <div className="col-xs-12">
                                                        <h1>จุดท่องเที่ยวตาม{items.name}</h1>
                                                    </div>
                                                    {storeObj.map((item, index) => (
                                                        <div className="col-xs-12 col-md-6" style={{ marginBottom: "30px" }} key={index}>
                                                            <div className="hotels-layout">
                                                                <div className="image-wrapper" style={{ display: "block", width: "100%" }}>
                                                                    <a href={"./../store/" + item.id} className="link">
                                                                        <img src={files[index] !== '' ? convertImage(path, files[index]) : 'assets/images/homepage/new-2.jpg'} alt="" className="img-responsive" />
                                                                    </a>
                                                                    <div className="title-wrapper">
                                                                        <a href={"./../store/" + item.id} className="title">{item.name}</a>
                                                                        <div title="Rated {item.rating_admin}.00 out of 5" className="star-rating">
                                                                            <span className="width-{item.rating_admin * 20}">
                                                                                <strong className="rating">{item.rating_admin}.00</strong> out of 5</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="content-wrapper" style={{ display: "block", width: "100%", marginBottom: "15px" }}>
                                                                    <div className="content" style={{ padding: "0px 25px" }}>
                                                                        <p className="text-left" style={{ height: '135px', marginTop: '15px' }}>{item.description === null ? (<div style={{ width: '100%' }}>-</div>) : stripTag(item.description).substring(0, 200)}</p>
                                                                        <div className="group-btn-tours">
                                                                            <a href={"./../store/" + item.id} className="left-btn">ดูรายละเอียด</a>
                                                                        </div>
                                                                    </div>
                                                                    {/* <ul className="list-info list-unstyled">
                                                                        <li>
                                                                            <a href="#" className="link">
                                                                                <i className="icons hidden-icon fa fa-eye"></i>
                                                                                <span className="number">234</span>
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#" className="link">
                                                                                <i className="icons hidden-icon fa fa-heart"></i>
                                                                                <span className="number">156</span>
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#" className="link">
                                                                                <i className="icons hidden-icon fa fa-comment"></i>
                                                                                <span className="number">19</span>
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a href=")" className="link">
                                                                                <i className="icons fa fa-share-alt"></i>
                                                                            </a>
                                                                            <ul className="share-social-list">
                                                                                <li>
                                                                                    <a href="#" className="link-social">
                                                                                        <i className="icons fa fa-facebook"></i>
                                                                                    </a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="#" className="link-social">
                                                                                        <i className="icons fa fa-google-plus"></i>
                                                                                    </a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="#" className="link-social">
                                                                                        <i className="icons fa fa-twitter"></i>
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#" className="link">
                                                                                <i className="icons fa fa-map-marker"></i>
                                                                            </a>
                                                                        </li>
                                                                    </ul> */}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row"> */}
                            {/* {files.map((item, index) => ( */}
                            {/* // <div key={index}>
                                                {item}<br/> */}
                            {/* { path.replace('/home/cmspirit/domains/chiangmaispirit.com/public_html/public', '') + item[index].replace('/home/cmspirit/domains/chiangmaispirit.com/public_html/public', '') }<br/> */}
                            {/* </div> */}
                            {/* // <div className="col-sm-4">
                                            //     <img src= { (path.replace('/home/cmspirit/domains/chiangmaispirit.com/public_html/public', '')) + item[index].replace('/home/cmspirit/domains/chiangmaispirit.com/public_html/public', '') } alt="a car on a road" className="img-responsive" />
                                            // </div> */}
                            {/* ))} */}
                            {/* </div> */}
                        </div>
                        <div className="col-md-4 sidebar-widget">
                            <SidebarSearch />
                            <SidebarTemples />
                        </div>
                    </div>
                </div>
            </section >

        );
    } else {
        return (
            <div></div>
        )
    }
}


export default Trailsview;