import React, { Component } from 'react';
import { stripTag, convertImage } from '../../../Helpers';

export default class WidgetNews extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <section className="news padding-top padding-bottom">
                <div className="container">
                    <div className="news-wrapper">
                        <div className="group-title">
                            <div className="sub-title">
                                <p className="text">Tourism Calendar </p>
                                <i className="icons flaticon-people"></i>
                            </div>
                            <h2 className="main-title">เปิดปฏิทินท่องเที่ยวจังหวัดเชียงใหม่ เที่ยวได้ทั้งปี ไม่มีพลาดอะไรดีๆ</h2>
                        </div>
                        <div className="news-content margin-top70">
                            <div className="news-list">
                                {this.props.items.map((item, index) => (
                                    <div className="new-layout" key={item.id}>
                                        <div className="image-wrapper">
                                            <a href={'./event/' + item.id} className="link">
                                                <img src={this.props.files[index] != '' ? convertImage(this.props.path, this.props.files[index]) : 'assets/images/homepage/new-2.jpg'} alt="" className="img-responsive" style={{ height: 400 }} />
                                            </a>
                                            <div className="description">{stripTag(item.detail).substring(0, 100)} ...</div>
                                        </div>
                                        <div className="content-wrapper">
                                            <a href={'./event/' + item.id} className="title">{item.name}</a>
                                            {/* <ul className="info list-inline list-unstyled">
                                                <li>
                                                    <a href="./temples" className="link">{item.start_datetime.substring(0, 10)} </a>
                                                </li>
                                                <li>
                                                    <a href="./temples" className="link">admin</a>
                                                </li>
                                            </ul> */}
                                            <p className="">{stripTag(item.detail).substring(0, 400)}</p>
                                            <a href={'./event/' + item.id} className="btn btn-maincolor" style={{ marginRight: '15px' }}>อ่านต่อ...</a>
                                            <a href={'./event'} className="btn">ดูทั้งหมด</a>
                                            {/* <div className="tags">
                                                <div className="title-tag">tags:</div>
                                                <ul className="list-inline list-unstyled list-tags">
                                                    <li>
                                                        <a href="blog-detail.html" className="tag">explore</a>
                                                    </li>
                                                    <li>
                                                        <a href="blog-detail.html" className="tag">adventure</a>
                                                    </li>
                                                    <li>
                                                        <a href="blog-detail.html" className="tag">traveler</a>
                                                    </li>
                                                </ul>
                                            </div> */}
                                        </div>
                                    </div>
                                ))}


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
