import React, { Component } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import SidebarSearch from '../../components/Sidebar/Search';
import SidebarTemples from '../../components/Sidebar/Temples';
import Eventlist from './eventlistComponent';


export default class Event extends Component {

    render() {

        return (
            <div className="wrapper-content">
                <Header />
                <div id="wrapper-content">

                    <div className="main-content">

                        <section className="page-banner blog" style={{ backgroundImage: "url(https://static.asiawebdirect.com/m/bangkok/portals/chiangmai-bangkok-com/homepage/events-festivals/allParagraphs/BucketComponent/ListingContainer/03/image/Songkran-Festival-Chiang-Mai.jpg)", backgroundPositionY: -250 }}>
                            {/* <section className="page-banner blog" style={{ backgroundImage: "url(" + Background + ")" }}> */}
                            <div className="container">
                                <div className="page-title-wrapper">
                                    <div className="page-title-content">
                                        <ol className="breadcrumb">
                                            <li>
                                                <a href="./" className="link home">หน้าแรก</a>
                                            </li>
                                            <li className="active">
                                                <a href="#" className="link">ปฏิทินท่องเที่ยว</a>
                                            </li>
                                        </ol>
                                        <div className="clearfix"></div>
                                        <h2 className="captions" style={{ maxWidth: '100%' }}>เที่ยวได้ทั้งปี ไม่มีพลาด</h2>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="page-main padding-top padding-bottom">
                            <div className="container">
                                <div className="row">
                                    <Eventlist />
                                    <div className="col-md-4 sidebar-widget">
                                        <SidebarSearch />
                                        <SidebarTemples />
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>

                    <div id="back-top">
                        <a href="#top" className="link">
                            <i className="fa fa-angle-double-up"></i>
                        </a>
                    </div>

                </div>

                <Footer />
            </div>


        );

    }
}