export function LoadJs(scriptToAppend) {

    const script = document.createElement('script');
    script.type = "text/javascript";
    script.src = scriptToAppend;
    script.async = true;
    document.body.appendChild(script);
    return scriptToAppend;

}

export function appendScript(pathfile) {
    const script = document.createElement('script');
    script.type = "text/javascript";
    script.src = process.env.PUBLIC_URL + pathfile;
    script.async = true;
    document.body.appendChild(script);
}

export function convertImage(p, f) {
    if (f !== '') {
        var randomItem = f[Math.floor(Math.random() * f.length)].replace('/home/cmspirit/domains/chiangmaispirit.com/public_html/public', '')
        return p + randomItem;
    }
}

export function convertMonth(i) {
    switch (i) {
        case '01': return 'ม.ค'; break;
        case '02': return 'ก.พ'; break;
        case '03': return 'มี.ค'; break;
        case '04': return 'เม.ย'; break;
        case '05': return 'พ.ค'; break;
        case '06': return 'มิ.ย'; break;
        case '07': return 'ก.ค'; break;
        case '08': return 'ส.ต'; break;
        case '09': return 'ก.ย'; break;
        case '10': return 'ต.ย'; break;
        case '11': return 'พ.ย'; break;
        case '12': return 'ธ.ค'; break;
        default: return null;
    }
}

export function stripTag(data) {
    return data.replace(/(<([^>]+)>)/gi, "");
}