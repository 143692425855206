import React, { Component } from 'react';

export default class WidgetVideos extends Component {
    render() {
        return (
            <section className="videos layout-1" style={{ backgroundColor: "#000" }, { backgroundImage: "none" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="video-wrapper padding-top padding-bottom">
                                <h5 className="sub-title">วิธีล้านนา
                                <strong>เที่ยวเชียงใหม่ </strong> เที่ยวได้ทั้งปี</h5>
                                <h2 className="title">เมืองที่คุณจะหลงรัก</h2>
                                <div className="text">
                                    เชียงใหม่เป็นเมืองที่ล้อมรอบด้วยภูเขา ตั้งอยู่ทางภาคเหนือของประเทศไทย ก่อตั้งขึ้นในปีพ.ศ.1839 ในฐานะเมืองหลวงของอาณาจักรล้านนาซึ่งเป็นรัฐอิสระจนถึงปีพ.ศ. 2101 บริเวณเขตเมืองเก่าของเชียงใหม่ยังคงมีซากกำแพงเมืองและคูเมืองโบราณที่ในอดีตเคยเป็นศูนย์กลางทางวัฒนธรรมและศาสนา และยังเป็นที่ตั้งของวัดทางพุทธศาสนาที่ก่อสร้างอย่างประณีตงดงามนับร้อยๆ แห่ง รวมถึงวัดพระสิงห์ที่สร้างขึ้นในศตวรรษที่ 14 และวัดเจดีย์หลวงที่สร้างขึ้นในศตวรรษที่ 15 ซึ่งประดับประดาด้วยรูปปั้นพญานาค
                            </div>
                                <a href="/trails"
                                    className="btn btn-maincolor">เส้นทางท่องเที่ยว</a>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="video-thumbnail">
                                <div className="video-bg">
                                    <img src="http://i3.ytimg.com/vi/lJ3CRnV3nQs/maxresdefault.jpg" alt="" className="img-responsive" />
                                </div>
                                <div className="video-button-play">
                                    <i className="icons fa fa-play"></i>
                                </div>
                                <div className="video-button-close"></div>
                                <iframe src="https://www.youtube.com/embed/lJ3CRnV3nQs?rel=0" allowFullScreen="1" className="video-embed"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}