import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Header extends Component {

    render() {
        return (
            <header>
                <div className="bg-transparent">

                    <div className="header-main">
                        <div className="container">
                            <div className="header-main-wrapper">
                                <div className="hamburger-menu">
                                    <div className="hamburger-menu-wrapper">
                                        <div className="icons"></div>
                                    </div>
                                </div>
                                <div className="navbar-header">
                                    <div className="logo">
                                        <a href="index.html" className="header-logo">
                                            <img src="../../images/Smallogo.gif" alt="" />
                                        </a>
                                    </div>
                                </div>
                                <nav className="navigation">

                                    <ul className="nav-links nav navbar-nav">
                                        <li>
                                            <a href="/" className="main-menu">
                                                <span className="text">หน้าแรก</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/temples" className="main-menu">
                                                <span className="text">วัดมรดกโลก</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/trails/all" className="main-menu">
                                                <span className="text">เส้นทางท่องเที่ยว</span>
                                                {/* <span className="icons-dropdown">
                                                    <i className="fa fa-angle-down"></i>
                                                </span> */}
                                            </a>
                                            {/* <ul className="dropdown-menu dropdown-menu-1">
                                                <li>
                                                    <a href="tour-result.html" className="link-page">ในเมือง</a>
                                                </li>
                                                <li>
                                                    <a href="tour-result.html" className="link-page">แม่ริม</a>
                                                </li>
                                                <li>
                                                    <a href="tour-view.html" className="link-page">สะเมิง</a>
                                                </li>
                                            </ul> */}
                                        </li>
                                        <li>
                                            <a href="/bank" className="main-menu">
                                                <span className="text">ธนาคารไข่</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/srisupan" className="main-menu">
                                                <span className="text">ทำบุญออนไลน์</span>
                                            </a>
                                        </li>

                                        <li className="button-search">
                                            <p className="main-menu">
                                                <i className="fa fa-search"></i>
                                            </p>
                                        </li>
                                    </ul>

                                    <div className="nav-search hide">
                                        <form>
                                            <input type="text" placeholder="Search" className="searchbox" />
                                            <button type="submit" className="searchbutton fa fa-search"></button>
                                        </form>
                                    </div>
                                </nav>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}